import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import {
  Range,
  RangeKeyDict,
  DateRangePicker as RdrPicker,
  DateRangePickerProps as RsrPickerProps,
} from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  isSameDay,
  startOfDay,
  differenceInDays,
  addMonths,
  startOfMonth,
  endOfDay,
} from "date-fns";
import { alpha, useTheme } from "@mui/material";
import { getDateStringFromDate, defineds, getDateFromToday } from "../../utils";

interface DateRangePickerProps extends RsrPickerProps {
  from?: Date;
  to?: Date;
  onRangeChange?: (from?: Date, to?: Date) => void;
  isSelectMonth?: boolean;
}

const DateRangePicker = ({
  from,
  to,
  onRangeChange,
  isSelectMonth = false,
  ...others
}: DateRangePickerProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dateFormat = isSelectMonth ? "MMM yyyy" : "MMM dd, yyyy";

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges: RangeKeyDict) => {
    const range = ranges[Object.keys(ranges)[0]];
    if (range.startDate && range.endDate) {
      onRangeChange &&
        onRangeChange(startOfDay(range.startDate), endOfDay(range.endDate));
    } else {
      onRangeChange && onRangeChange(range.startDate, range.endDate);
    }
  };

  return (
    <>
      <Button
        size="large"
        variant="outlined"
        onClick={handleClick}
        endIcon={<DateRangeIcon />}
      >
        <span style={{ textTransform: "none" }}>
          {from && to
            ? `${getDateStringFromDate(
                from,
                dateFormat
              )} - ${getDateStringFromDate(to, dateFormat)}`
            : "All time"}
        </span>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          <RdrPicker
            {...others}
            retainEndDateOnFirstSelection
            ranges={[{ startDate: from, endDate: to }]}
            rangeColors={[alpha(palette.primary.main, 0.75)]}
            onChange={handleSelect}
            inputRanges={[]}
            renderStaticRangeLabel={(staticRange) => (
              <span
                style={
                  from && to
                    ? {}
                    : {
                        fontWeight: 600,
                        color: alpha(palette.primary.main, 0.75),
                      }
                }
              >
                {staticRange.label}
              </span>
            )}
            staticRanges={[
              {
                label: "Today",
                isSelected: (range: Range) =>
                  Boolean(
                    range.startDate &&
                      range.endDate &&
                      isSameDay(range.startDate, new Date()) &&
                      isSameDay(range.endDate, new Date())
                  ),
                range: () => ({
                  startDate: defineds.startOfToday,
                  endDate: defineds.endOfToday,
                }),
              },
              {
                label: "Last 7 days",
                isSelected: (range: Range) =>
                  Boolean(
                    range.startDate &&
                      range.endDate &&
                      isSameDay(range.startDate, getDateFromToday(-6)) &&
                      isSameDay(range.endDate, new Date())
                  ),
                range: () => ({
                  startDate: startOfDay(getDateFromToday(-6)),
                  endDate: defineds.endOfToday,
                }),
              },
              {
                label: "Last 30 days",
                isSelected: (range: Range) =>
                  Boolean(
                    range.startDate &&
                      range.endDate &&
                      isSameDay(range.startDate, getDateFromToday(-29)) &&
                      isSameDay(range.endDate, new Date())
                  ),
                range: () => ({
                  startDate: startOfDay(getDateFromToday(-29)),
                  endDate: defineds.endOfToday,
                }),
              },
              {
                label: "Last 12 months",
                isSelected: (range: Range) =>
                  Boolean(
                    range.startDate &&
                      range.endDate &&
                      isSameDay(
                        range.startDate,
                        startOfMonth(addMonths(new Date(), -11))
                      ) &&
                      isSameDay(range.endDate, defineds.endOfToday)
                  ),
                range: () => ({
                  startDate: startOfMonth(addMonths(new Date(), -11)),
                  endDate: defineds.endOfToday,
                }),
              },
              {
                label: "All time",
                hasCustomRendering: true,
                isSelected: () => false,
                range: () => ({
                  startDate: undefined,
                  endDate: undefined,
                }),
              },
              {
                label: "Custom range",
                isSelected: (range: Range) => {
                  return Boolean(
                    !range.startDate ||
                      !range.endDate ||
                      !isSameDay(range.endDate, new Date()) ||
                      ([0, 6, 29].indexOf(
                        differenceInDays(range.endDate, range.startDate)
                      ) === -1 &&
                        !isSameDay(
                          range.startDate,
                          startOfMonth(addMonths(new Date(), -11))
                        ))
                  );
                },
                range: () => ({
                  startDate: from,
                  endDate: to,
                }),
              },
            ]}
          />
        </Box>
      </Popover>
    </>
  );
};

export default DateRangePicker;
