import React from "react";
import get from "lodash/get";
import { useRecordContext } from "react-admin";
import Avatar, { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";
import assetImages from "../../assets/images";

interface AvatarProps {
  source: string;
  size?: number;
  avatarProps?: Partial<MuiAvatarProps>;
}

const AvatarField = ({ source, size = 40, avatarProps }: AvatarProps) => {
  const record = useRecordContext();
  const avatar = get(record, source);
  return (
    <Avatar
      {...avatarProps}
      sx={{ width: size, height: size }}
      alt="avatar"
      src={avatar || assetImages.defaultAvatar}
    />
  );
};

export default AvatarField;
