import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { getDateStringFromMilliseconds } from "../utils";
import {
  StickyTableCell,
  TableHeadCell,
  StyledTableRow,
  TableFooterCell,
} from "./styles";
import { IEpisodeStatsData } from "./types";

interface EpisodesStatsProps {
  data: IEpisodeStatsData[];
  loading: boolean;
}

const MIN_ROW = 8;

const EpisodesStats = ({ data, loading }: EpisodesStatsProps) => {
  const renderEmptyRows = (amount: number) => {
    const result = [];
    for (let i = 0; i < amount; i++) {
      result.push(
        <StyledTableRow style={{ height: 53 }}>
          <StickyTableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </StyledTableRow>
      );
    }
    return result;
  };

  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={500}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <TableContainer style={{ height: 500, position: "relative" }}>
      <Table stickyHeader style={{ height: "100%", position: "absolute" }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "white" }}>
            <StickyTableCell header sx={{ minWidth: { xs: 200, md: 400 } }}>
              Episode
            </StickyTableCell>
            <TableHeadCell align="center" sx={{ minWidth: 150 }}>
              Published Date
            </TableHeadCell>
            <TableHeadCell align="center" sx={{ minWidth: 150 }}>
              Status
            </TableHeadCell>
            <TableHeadCell align="center" sx={{ minWidth: 150 }}>
              Plays
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.episodeId}>
              <StickyTableCell>
                <strong
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {row.title}
                </strong>
              </StickyTableCell>
              <TableCell align="center">
                {getDateStringFromMilliseconds(
                  (row.publishedAt?.seconds || 0) * 1000
                )}
              </TableCell>
              <TableCell align="center">
                {row.visibility
                  ? `${row.visibility[0]}${row.visibility
                      .slice(1)
                      .toLowerCase()}`
                  : ""}
              </TableCell>
              <TableCell align="center">{row.totalViews}</TableCell>
            </StyledTableRow>
          ))}
          {renderEmptyRows(Math.max(0, MIN_ROW - data.length))}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StickyTableCell footer>
              <strong>Total</strong>
            </StickyTableCell>
            <TableFooterCell colSpan={2} />
            <TableFooterCell align="center">
              <strong>
                {data.reduce((sum, row) => sum + row.totalViews, 0)}
              </strong>
            </TableFooterCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default EpisodesStats;
