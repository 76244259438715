import { useEffect, useState } from "react";
import { FieldProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TrueIcon from "@mui/icons-material/Verified";
import FalseIcon from "@mui/icons-material/Clear";
import {
  ChannelField,
  IChannel,
  IUser,
  UserField,
} from "../../models/entities";
import { getChannelByUserId } from "../../services/firebase/channel";
import { CategoryMapField } from "../../models/valueObjects";
import InfomationRow from "../../components/InfomationRow";
import assetImages from "../../assets/images";

const UserChannelDetail = (props: FieldProps) => {
  const user = useRecordContext<IUser>();
  const [channel, setChannel] = useState<IChannel>();
  const [loading, setLoading] = useState(false);
  const userId = user[UserField.ID];

  useEffect(() => {
    const getChannel = async (id: string) => {
      setLoading(true);
      const channel = await getChannelByUserId(id);
      setChannel(channel);
      setLoading(false);
    };
    getChannel(userId);
  }, [userId]);

  if (loading) {
    return (
      <Box
        width="100%"
        height={400}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!channel) return null;

  return (
    <Stack py={4} px={2} gap={2}>
      <InfomationRow label="Verified">
        {channel[ChannelField.IS_VERIFIED] ? (
          <TrueIcon color="success" />
        ) : (
          <FalseIcon color="error" />
        )}
      </InfomationRow>
      <InfomationRow label="RSS Url">
        {user[UserField.IS_EXTERNAL_RSS_ACCOUNT] ? (
          <Typography variant="body1" color="text.disabled">
            This is an external RSS account
          </Typography>
        ) : (
          <Link
            href={`${process.env.REACT_APP_RSS_URL}/${channel.id}`}
            variant="body1"
          >
            {`${process.env.REACT_APP_RSS_URL}/${channel.id}`}
          </Link>
        )}
      </InfomationRow>
      <InfomationRow label="Title">
        <Typography variant="body1">{channel[ChannelField.TITLE]}</Typography>
      </InfomationRow>
      <InfomationRow label="Description">
        <Typography
          variant="body1"
          color={channel[ChannelField.DESCRIPTION] ? "text" : "text.disabled"}
        >
          {channel[ChannelField.DESCRIPTION] || "null"}
        </Typography>
      </InfomationRow>
      <InfomationRow label="Publisher Name">
        <Typography variant="body1">
          {channel[ChannelField.PUBLISHER_NAME]}
        </Typography>
      </InfomationRow>
      <InfomationRow label="Publisher Email">
        <Link
          href={`mailto:${channel[ChannelField.WEBSITE_URL]}`}
          variant="body1"
        >
          {channel[ChannelField.PUBLISHER_EMAIL]}
        </Link>
      </InfomationRow>
      <InfomationRow label="Website">
        <Link href={channel[ChannelField.WEBSITE_URL]} variant="body1">
          {channel[ChannelField.WEBSITE_URL]}
        </Link>
      </InfomationRow>
      <InfomationRow label="Category">
        {channel[ChannelField.CATEGORIES]?.length ? (
          <Stack flexDirection="row" gap={1}>
            {channel[ChannelField.CATEGORIES].map((category, index) => (
              <Chip
                key={index}
                label={get(
                  category,
                  `${CategoryMapField.SUBCATEGORIES}.0`,
                  category[CategoryMapField.TEXT]
                )}
              />
            ))}
          </Stack>
        ) : (
          <Typography variant="body1" color="text.disabled">
            null
          </Typography>
        )}
      </InfomationRow>
      <InfomationRow label="Explicit">
        <Switch
          size="small"
          disabled
          checked={channel[ChannelField.IS_EXPLICIT]}
        />
      </InfomationRow>
      <InfomationRow label="Cover art">
        <img
          height={200}
          width={200}
          src={channel[ChannelField.THUMBNAIL]}
          alt="channel-thumbnail"
          style={{ marginTop: "6px" }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = assetImages.defaultPlaceholder;
          }}
        />
      </InfomationRow>
    </Stack>
  );
};

export default UserChannelDetail;
