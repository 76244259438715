import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { validateUsername } from "../../utils";
import { getUserByUsername } from "../../services/firebase/user";
import {
  ExternalRssFeedMapField,
  IExternalRssFeedMap,
} from "../../models/valueObjects";

interface ImportRssStep3Props {
  handleGoBack: () => void;
  handleConfirm: (username: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  feed?: IExternalRssFeedMap;
}

const ImportRssStep3 = ({
  handleGoBack,
  handleConfirm,
  loading,
  setLoading,
  feed,
}: ImportRssStep3Props) => {
  const [username, setUsername] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (feed) {
      onUsernameChange(feed[ExternalRssFeedMapField.AUTHOR_NAME] || "");
    } else {
      setUsername("");
    }
  }, [feed]);

  const onUsernameChange = (input: string) => {
    setUsername(
      input
        .toLowerCase()
        .replace(" ", "_")
        .replace(/[^a-z0-9._]/g, "")
    );
  };

  const handleOkButtonClick = async () => {
    setErrMsg("");
    setLoading(true);
    const user = await getUserByUsername(username);
    if (user) {
      setErrMsg("This username is not available");
      setLoading(false);
      return;
    }
    handleConfirm(username);
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          marginTop: 6,
          marginBottom: 8,
        }}
      >
        <DialogContentText color="#333" fontSize={24}>
          Enter an username
        </DialogContentText>
        <DialogContentText color="#333" marginBottom={1}>
          This user will appear on our application as the owner of this RSS feed
        </DialogContentText>
        <TextField
          helperText={errMsg}
          error={!!errMsg}
          value={username}
          onChange={(event) => onUsernameChange(event.target.value)}
          autoFocus
          margin="dense"
          label="Enter an username"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" disabled={loading} onClick={handleGoBack}>
          Back
        </Button>
        <Button
          size="large"
          disabled={!validateUsername(username) || loading}
          onClick={handleOkButtonClick}
          autoFocus
        >
          Finish
        </Button>
      </DialogActions>
    </>
  );
};

export default ImportRssStep3;
