import { useState } from "react";
import { TopToolbar, Button, useNotify, ExportButton } from "react-admin";
import NotificationIcon from "@mui/icons-material/NotificationsNone";
import SendNotificationDialog from "../../components/SendNotificationDialog";
import { adminSendNotification } from "../../services/firebase/functions/adminSendNotification";

const UserListActions = () => {
  const notify = useNotify();
  const [sendingNotification, setSendingNotification] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const onSend = async (title: string, body: string) => {
    try {
      setSendingNotification(true);
      await adminSendNotification(title, body);
      setSendingNotification(false);
      notify("Send notification to all users successfully", {
        type: "success",
      });
    } catch (err) {
      notify((err as Error).message, { type: "error" });
    }
    setOpenNotification(false);
  };

  return (
    <>
      <TopToolbar sx={{ alignItems: "center" }}>
        <Button
          onClick={() => setOpenNotification(true)}
          label="Send notification"
        >
          <NotificationIcon />
        </Button>
        <ExportButton />
      </TopToolbar>
      <SendNotificationDialog
        dialogTitle="Send notification to all users"
        loading={sendingNotification}
        open={openNotification}
        onSend={onSend}
        onClose={() => setOpenNotification(false)}
      />
    </>
  );
};

export default UserListActions;
