import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import get from "lodash/get";
import {
  DatagridConfigurable,
  List,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  ListProps,
  FunctionField,
  SelectInput,
  TextInput,
} from "react-admin";
import { Link as LinkRouter } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {
  ChannelField,
  EpisodeField,
  EpisodeVisibility,
  IEpisode,
  IUser,
  UserField,
} from "../models/entities";
import { AudioMapField, PersonalInfoMapField } from "../models/valueObjects";
import { Collection } from "../models/constants";
import AvatarField from "../components/AvatarField";
import EditableBooleanField from "../components/EditableBooleanField";
import { usePlayerContext } from "../contexts/PlayerContext";
import { getDurationString } from "../utils";
import SelectableField from "../components/SelectableField";
import ChannelRelatedWrapper from "../components/ChannelRelatedWrapper";
import { useAppContext } from "../contexts/AppContext";
import StyledBooleanField from "../components/StyledBooleanField";
import EpisodeListActions from "./EpisodeListActions";
import LikePerViewField from "./components/LikePerViewField";
import { useTypographyStyle } from "../globals/styles";
import EpisodeRowActions from "./components/EpisodeRowActions";

const postFilters = (disabled: boolean) => [
  <TextInput
    disabled={disabled}
    sx={{ marginBottom: "4px", minWidth: 400 }}
    label="Search"
    source={EpisodeField.TITLE}
    alwaysOn
  />,
  <SelectInput
    disabled={disabled}
    alwaysOn
    source={EpisodeField.VISIBILITY}
    choices={[
      { id: EpisodeVisibility.PUBLIC, name: EpisodeVisibility.PUBLIC },
      { id: EpisodeVisibility.PRIVATE, name: EpisodeVisibility.PRIVATE },
      { id: EpisodeVisibility.DRAFT, name: EpisodeVisibility.DRAFT },
    ]}
  />,
];

const EpisodeList = (props: ListProps) => {
  const classes = useTypographyStyle();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadChannelData } = useAppContext();
  const [filteringDiscover, setFilteringDiscover] = useState(false);
  const filterParams = searchParams.get("filter");
  const isFiltering =
    filteringDiscover || (filterParams ? filterParams !== "{}" : false);
  const { playEpisodes, currentEpisodeIndex, playlist, togglePlay } =
    usePlayerContext();
  const playingEpisode = get(playlist, currentEpisodeIndex, undefined);

  useEffect(() => {
    if (isFiltering) {
      searchParams.delete("sort");
      searchParams.delete("order");
      setSearchParams(searchParams);
    } else if (!searchParams.has("sort") || searchParams.get("sort") === "id") {
      searchParams.set("sort", EpisodeField.VIEWS_COUNT);
      searchParams.set("order", "DESC");
      setSearchParams(searchParams);
    }
  }, [isFiltering, searchParams, setSearchParams]);

  return (
    <List
      filters={postFilters(filteringDiscover)}
      {...props}
      className="custom-pagination-list"
      actions={
        <EpisodeListActions
          filteringDiscover={filteringDiscover}
          setFilteringDiscover={setFilteringDiscover}
        />
      }
      queryOptions={{
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: ({ data }: { data: IEpisode[] }) => {
          loadChannelData(data.map((el) => el[EpisodeField.USER_ID]!));
        },
        meta: { discoverFilter: filteringDiscover },
      }}
    >
      <DatagridConfigurable
        omit={[
          `${EpisodeField.AUDIO}.${AudioMapField.DURATION}`,
          EpisodeField.CREATED_AT,
          EpisodeField.VISIBILITY,
        ]}
        rowStyle={(record) => {
          if (record.id === playingEpisode?.id) {
            return { backgroundColor: "rgba(224, 224, 224, 0.4)" };
          }
        }}
        bulkActionButtons={false}
        rowClick={(id, _, record) => {
          if (id !== playingEpisode?.id) {
            playEpisodes([record] as IEpisode[]);
          } else {
            togglePlay();
          }
          return false;
        }}
      >
        <ReferenceField
          label="Episode owner"
          sortable={!isFiltering}
          sortByOrder="DESC"
          source={EpisodeField.USER_ID}
          reference={Collection.USERS}
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <AvatarField
              size={32}
              source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.AVATAR}`}
            />
            <FunctionField
              render={(user: IUser) => (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/users/${user[UserField.ID]}/show`}
                  component={LinkRouter}
                >
                  {user[UserField.USERNAME]}
                </Link>
              )}
            />
          </Stack>
        </ReferenceField>
        <FunctionField
          className={classes.limitLineTypography}
          label="Title"
          sortable={!isFiltering}
          sortByOrder="DESC"
          source={EpisodeField.TITLE}
          render={(episode: IEpisode) => (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/episodes/${episode[EpisodeField.ID]}/show`}
              component={LinkRouter}
            >
              {episode[EpisodeField.TITLE]}
            </Link>
          )}
        />
        <TextField
          className={classes.limitLineTypography}
          source={EpisodeField.DESCRIPTION}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <ChannelRelatedWrapper
          userIdKey={EpisodeField.USER_ID}
          label="Podcast title"
          source={EpisodeField.USER_ID}
          sortable={!isFiltering}
          sortByOrder="DESC"
        >
          <TextField source={ChannelField.TITLE} />
        </ChannelRelatedWrapper>
        <FunctionField
          source={`${EpisodeField.AUDIO}.${AudioMapField.DURATION}`}
          sortable={!isFiltering}
          sortByOrder="DESC"
          label="Duration"
          textAlign="center"
          sortBy={`${EpisodeField.AUDIO}.${AudioMapField.DURATION}`}
          render={(record: IEpisode) =>
            `${getDurationString(record.audio?.duration || 0)}`
          }
        />
        <DateField
          label="Recorded at"
          source={EpisodeField.CREATED_AT}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <DateField
          source={EpisodeField.PUBLISHED_AT}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <TextField
          source={EpisodeField.VISIBILITY}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <EditableBooleanField
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={EpisodeField.SHOW_ON_DISCOVER}
        />
        <StyledBooleanField
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={EpisodeField.USER_SHOW_ON_DISCOVER}
        />
        <SelectableField
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={EpisodeField.DISCOVER_PRIORITY}
          adaptNewData={(value) => ({
            [EpisodeField.DISCOVER_PRIORITY]: value,
            // [EpisodeField.CURRENT_DISCOVER_PRIORITY]: value,
            [EpisodeField.UPDATED_AT]: new Date(),
            [EpisodeField.UPDATED_DISCOVER_PRIORITY_AT]: new Date(),
          })}
          menuItemProps={{ style: { minWidth: 60, justifyContent: "center" } }}
          options={Array.from({ length: 10 }, (_, index) => ({
            value: 10 - index,
          }))}
        />
        <DateField
          source={EpisodeField.UPDATED_DISCOVER_PRIORITY_AT}
          label="Updated priority at"
          options={{
            timeStyle: "short",
            dateStyle: "short",
            hourCycle: "h24",
          }}
          locales="en-GB"
          showTime
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <NumberField
          source={EpisodeField.VIEWS_COUNT}
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <NumberField
          source={EpisodeField.LIKES_COUNT}
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <LikePerViewField label="Like-to-view percent" textAlign="center" />
        <StyledBooleanField
          source={EpisodeField.IS_EXTERNAL_RSS_EPISODE}
          label="Is RSS Episode"
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <EpisodeRowActions label="Actions" textAlign="center" />
      </DatagridConfigurable>
    </List>
  );
};

export default EpisodeList;
