import TrueIcon from "@mui/icons-material/Verified";
import { UseRecordContextParams, useRecordContext } from "react-admin";
import get from "lodash/get";
import FalseIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";

const StyledBooleanField = (props: UseRecordContextParams) => {
  const record = useRecordContext();
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {get(record, props.source, false) ? (
        <TrueIcon color="success" />
      ) : (
        <FalseIcon color="error" />
      )}
    </Box>
  );
};

export default StyledBooleanField;
