export enum MailchimpTag {
  INSTALLED_APP = "InstalledApp",
  RSS_PUBLISHED = "RSSPublished",
  TIKTOK = "TikTok",
  WEB_SIGNUP = "WebSignup",
}

export enum MailchimpMapField {
  CHANNEL_ID = "channelId",
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  RSS_URL = "rssUrl",
  TAGS = "tags",
}

export interface IMailchimpMap {
  [MailchimpMapField.EMAIL]?: string;
  [MailchimpMapField.FIRST_NAME]?: string;
  [MailchimpMapField.LAST_NAME]?: string;
  [MailchimpMapField.RSS_URL]?: string;
  [MailchimpMapField.TAGS]?: string[];
}
