import {
  ExternalRssFeedMapField,
  IExternalRssFeedMap,
} from "../../models/valueObjects";

export const validateExternalRssFeed = (feed: IExternalRssFeedMap) => {
  if (!feed[ExternalRssFeedMapField.TITLE]) {
    return { isValid: false, message: "This RSS feed is missing title" };
  }
  if (!feed[ExternalRssFeedMapField.AUTHOR_NAME]) {
    return {
      isValid: false,
      message: "This RSS feed is missing author infomation",
    };
  }
  if (!feed.items?.length) {
    return {
      isValid: false,
      message: "This RSS feed does not contain any episodes",
    };
  }

  return { isValid: true, message: "Valid feed" };
};
