import has from "lodash/has";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import mapValues from "lodash/mapValues";
import { Timestamp, increment, GeoPoint } from "firebase/firestore";

export const mapValuesDeep = (obj: any): any => {
  if (has(obj, "_seconds") && has(obj, "_nanoseconds")) {
    const { _seconds, _nanoseconds } = obj;
    return Timestamp.fromDate(
      new Date(_seconds * 1000 + _nanoseconds / 1000000)
    );
  } else if (has(obj, "seconds") && has(obj, "nanoseconds")) {
    const { seconds, nanoseconds } = obj;
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  } else if (has(obj, "operand")) {
    const { operand } = obj;
    return increment(operand);
  } else if (has(obj, "_latitude") && has(obj, "_longitude")) {
    const { _latitude, _longitude } = obj;
    return new GeoPoint(_latitude, _longitude);
  } else if (isArray(obj)) {
    return obj.map((innerObj) => mapValuesDeep(innerObj));
  } else if (isObject(obj)) {
    return mapValues(obj, (val) => mapValuesDeep(val));
  }
  return obj;
};
