import { FieldProps, useGetOne, useRecordContext } from "react-admin";
import TrueIcon from "@mui/icons-material/Verified";
import FalseIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import {
  IMailchimpData,
  IUser,
  MailchimpDataField,
  UserField,
} from "../../models/entities";
import { MailchimpMapField } from "../../models/valueObjects";
import { Collection } from "../../models/constants";

export const SyncedMailchimpField = (props: FieldProps) => {
  const record = useRecordContext<IUser>();
  const { data, isLoading } = useGetOne<IMailchimpData>(
    Collection.MAILCHIMP_DATA,
    {
      id: record[UserField.ID],
    }
  );

  if (isLoading) {
    return <></>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {data?.[MailchimpDataField.MAILCHIMP]?.[MailchimpMapField.EMAIL] ? (
        <TrueIcon color="success" />
      ) : (
        <FalseIcon color="error" />
      )}
    </Box>
  );
};

export default SyncedMailchimpField;
