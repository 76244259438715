export enum UserDeviceDataMapField {
  ID = 'deviceId',
  NAME = 'deviceName',
  TYPE = 'deviceType',
  NOTIFICATION_TOKEN = 'notificationToken',
}

export interface IUserDeviceDataMap {
  [UserDeviceDataMapField.ID]: string;
  [UserDeviceDataMapField.NAME]: string;
  [UserDeviceDataMapField.TYPE]: string;
  [UserDeviceDataMapField.NOTIFICATION_TOKEN]: string;
}
