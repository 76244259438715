import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";

export const adminSendNotification = async (
  title: string,
  body: string,
  receiverId?: string
) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "adminSendNotification"
    )({ title, body, receiverId });
    return data.result;
  } catch {
    throw new Error("Send notification to user failed");
  }
};
