import styled from "styled-components";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

export const LoadingStack = styled(Stack)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  cursor: progress;
  justify-content: center;
  align-items: center;
`;

export const useTypographyStyle = makeStyles(() => ({
  limitLineTypography: {
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    maxWidth: 450,
  },
}));
