import { Menu } from "react-admin";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { Collection } from "./models/constants";

const AdminMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.ResourceItem name={Collection.USERS} />
    <Menu.ResourceItem name={Collection.EPISODES} />
    <Menu.Item
      to="/best-of-stats"
      primaryText={`"Best Of" Statistic`}
      leftIcon={<EqualizerIcon />}
    />
    <Menu.ResourceItem name={Collection.EXTERNAL_RSS_SOURCES} />
  </Menu>
);

export default AdminMenu;
