import { Timestamp } from "firebase/firestore";

export enum ExternalRssItemMapField {
  GUID = "guid",
  TITLE = "title",
  DESCRIPTION = "description",
  URL = "url",
  DURATION = "duration",
  PUBLISHED_AT = "publishedAt",
  IS_EXPLICIT = "isExplicit",
}

export interface IExternalRssItemMap {
  [ExternalRssItemMapField.GUID]?: string;
  [ExternalRssItemMapField.TITLE]?: string;
  [ExternalRssItemMapField.DESCRIPTION]?: string;
  [ExternalRssItemMapField.URL]?: string;
  [ExternalRssItemMapField.DURATION]?: number;
  [ExternalRssItemMapField.PUBLISHED_AT]?: Timestamp;
  [ExternalRssItemMapField.IS_EXPLICIT]?: boolean;
}
