import { ICategoryMap } from './categoryMap';
import { IExternalRssItemMap } from './externalRssItem';

export enum ExternalRssFeedMapField {
  TITLE = 'title',
  DESCRIPTION = 'description',
  AUTHOR_NAME = 'authorName',
  AUTHOR_EMAIL = 'authorEmail',
  IMAGE = 'image',
  CATEGORIES = 'categories',
  ITEMS = 'items',
}

export interface IExternalRssFeedMap {
  [ExternalRssFeedMapField.TITLE]?: string;
  [ExternalRssFeedMapField.DESCRIPTION]?: string;
  [ExternalRssFeedMapField.AUTHOR_NAME]?: string;
  [ExternalRssFeedMapField.AUTHOR_EMAIL]?: string;
  [ExternalRssFeedMapField.IMAGE]?: string;
  [ExternalRssFeedMapField.CATEGORIES]?: ICategoryMap[];
  [ExternalRssFeedMapField.ITEMS]?: IExternalRssItemMap[];
}
