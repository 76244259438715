import { Timestamp } from "firebase/firestore";
import { IAudioMap, IOriginMap } from "../valueObjects";

export enum EpisodeField {
  ID = "id",
  AUDIO = "audio",
  PUBLIC_AUDIO = "publicAudio",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  CONTRIBUTED_COLLABORATOR_IDS = "contributedCollaboratorIds",
  DISPLAYED_ON_PROFILE_OF_USER_IDS = "displayedOnProfileOfUserIds",
  USER_ID = "userId",
  TYPE = "type",
  PROCESS_STATUS = "processStatus",
  VISIBILITY = "visibility",
  PUBLISHED_AT = "publishedAt",
  ORIGIN = "origin",
  TITLE = "title",
  DESCRIPTION = "description",
  IS_EXPLICIT = "isExplicit",
  VIEWS_COUNT = "viewCount",
  LIKES_COUNT = "likeCount",
  INTERNAL_VIEWS_COUNT = "internalViewCount",
  HAS_BEEN_PREVIOUSLY_PUBLISHED = "hasBeenPreviouslyPublished",
  SHOW_ON_DISCOVER = "showOnDiscover",
  DISCOVER_PRIORITY = "discoverPriority",
  CURRENT_DISCOVER_PRIORITY = "currentDiscoverPriority",
  UPDATED_DISCOVER_PRIORITY_AT = "updatedDiscoverPriorityAt",
  USER_SHOW_ON_DISCOVER = "userShowOnDiscover",
  IS_EXTERNAL_RSS_EPISODE = "isExternalRssEpisode",
}

export enum EpisodeVisibility {
  DRAFT = "DRAFT",
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export type TEpisodeVisibility =
  (typeof EpisodeVisibility)[keyof typeof EpisodeVisibility];

export enum EpisodeProcessStatus {
  IDLE = "IDLE",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
}

export type TEpisodeProcessStatus =
  (typeof EpisodeProcessStatus)[keyof typeof EpisodeProcessStatus];

export enum EpisodeType {
  NORMAL = "NORMAL",
  COLLABORATION = "COLLABORATION",
}

export type TEpisodeType = (typeof EpisodeType)[keyof typeof EpisodeType];

export interface IEpisode {
  [EpisodeField.ID]: string;
  [EpisodeField.AUDIO]?: IAudioMap | null;
  [EpisodeField.PUBLIC_AUDIO]?: IAudioMap | null;
  [EpisodeField.CREATED_AT]?: Timestamp;
  [EpisodeField.UPDATED_AT]?: Timestamp;
  [EpisodeField.CONTRIBUTED_COLLABORATOR_IDS]?: string[];
  [EpisodeField.DISPLAYED_ON_PROFILE_OF_USER_IDS]?: string[];
  [EpisodeField.USER_ID]?: string;
  [EpisodeField.TYPE]?: TEpisodeType;
  [EpisodeField.PROCESS_STATUS]?: TEpisodeProcessStatus;
  [EpisodeField.VISIBILITY]?: TEpisodeVisibility;
  [EpisodeField.PUBLISHED_AT]?: Timestamp | null;
  [EpisodeField.ORIGIN]?: IOriginMap;
  [EpisodeField.TITLE]?: string;
  [EpisodeField.DESCRIPTION]?: string;
  [EpisodeField.IS_EXPLICIT]?: boolean;
  [EpisodeField.LIKES_COUNT]?: number;
  [EpisodeField.VIEWS_COUNT]?: number;
  [EpisodeField.INTERNAL_VIEWS_COUNT]?: number;
  [EpisodeField.HAS_BEEN_PREVIOUSLY_PUBLISHED]?: boolean;
  [EpisodeField.SHOW_ON_DISCOVER]?: boolean;
  [EpisodeField.DISCOVER_PRIORITY]?: number;
  [EpisodeField.CURRENT_DISCOVER_PRIORITY]?: number;
  [EpisodeField.UPDATED_DISCOVER_PRIORITY_AT]?: Timestamp;
  [EpisodeField.USER_SHOW_ON_DISCOVER]?: boolean;
  [EpisodeField.IS_EXTERNAL_RSS_EPISODE]?: boolean;
}
