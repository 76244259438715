import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { EmptyBodyCell, TableHeadCell } from './styles';
import { StatsTableProps } from './types';
import StatsTableRow from './StatsTableRow';

const StatsTable = ({ columns, rows = [], valueKey }: StatsTableProps) => {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (valueKey) {
      setTotal(
        rows.map((el) => get(el, valueKey, 0) as number).reduce((sum, value) => sum + value, 0)
      );
    }
  }, [rows, valueKey]);

  return (
    <TableContainer sx={{ maxHeight: { xs: 260, md: 400, lg: 500 } }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableHeadCell key={`header_${index}`} {...col.headerProps}>
                <Tooltip title={col.subTitle ?? ''}>
                  <span>{col.text}</span>
                </Tooltip>
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows
              .sort((a, b) => (b.views as number) - (a.views as number))
              .map((row, i) => (
                <StatsTableRow
                  index={i}
                  key={`row_${i}`}
                  columns={columns}
                  data={row}
                  valueKey={valueKey}
                  total={total}
                />
              ))
          ) : (
            <TableRow>
              <EmptyBodyCell colSpan={columns.length}>No data available</EmptyBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export * as StatsTableTypes from './types';
export default StatsTable;
