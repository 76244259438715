import { useState } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { validateHttpUrl } from "../../utils";
import { getExternalRssSourceByRssUrl } from "../../services/firebase/externalRssSource";
import { externalRssSourceHelper } from "../../helpers";
import { IExternalRssFeedMap } from "../../models/valueObjects";
import { parseExternalRssFeed } from "../../services/firebase/functions/parseExternalRssFeed";

interface ImportRssStep1Props {
  handleClose: () => void;
  moveToNextStep: (url: string, feed: IExternalRssFeedMap) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const ImportRssStep1 = ({
  handleClose,
  moveToNextStep,
  loading,
  setLoading,
}: ImportRssStep1Props) => {
  const [url, setUrl] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handleAdd = async () => {
    setErrMsg("");
    setLoading(true);
    const rssSource = await getExternalRssSourceByRssUrl(url);
    if (rssSource) {
      setErrMsg("This RSS feed is already imported");
      setLoading(false);
      return;
    }
    try {
      const feed: IExternalRssFeedMap = await parseExternalRssFeed(url);
      const { isValid, message } =
        externalRssSourceHelper.validateExternalRssFeed(feed);
      if (!isValid) {
        setErrMsg(message);
      } else {
        moveToNextStep(url, feed);
      }
    } catch (err) {
      console.log(err);
      setErrMsg("Failed to read this RSS feed");
    }
    setLoading(false);
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          marginTop: 6,
          marginBottom: 8,
        }}
      >
        <DialogContentText color="#333" fontSize={24}>
          Enter a podcast RSS feed
        </DialogContentText>
        <DialogContentText color="#333" marginBottom={1}>
          Copy the show&rsquo;s RSS feed you want to import, paste it here, then
          click Add
        </DialogContentText>
        <TextField
          helperText={errMsg}
          error={!!errMsg}
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          autoFocus
          margin="dense"
          label="Enter RSS feed URL"
          type="url"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="large"
          disabled={!validateHttpUrl(url) || loading}
          onClick={handleAdd}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};
export default ImportRssStep1;
