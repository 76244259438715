import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

export const TableHeadCell = styled(TableCell)(({ theme }) => ({
  padding: "10px 12px",
  fontWeight: 700,
  color: theme.palette.text.disabled,
}));

export const TableBodyCell = styled(TableCell)`
  padding: 10px 12px;
`;

export const EmptyBodyCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
