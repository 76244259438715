import { auth } from "../services/firebase/clientApp";

export const getUserIdLoggedIn = async () => {
  return new Promise<string | undefined>((resolve, reject) => {
    if (auth.currentUser) return resolve(auth.currentUser.uid);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user?.uid);
    });
  });
};
