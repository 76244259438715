import { Exporter, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { get, keyBy } from "lodash";
import { ChannelField, IUser, UserField } from "../../models/entities";
import { getChannelByUserIds } from "../../services/firebase/channel";

const UserExporter: Exporter = async (users: IUser[]) => {
  const channels = await getChannelByUserIds(
    users.map((user) => user[UserField.ID])
  );
  const channelMap = keyBy(channels, ChannelField.USER_ID);
  const data = users.map((user) => {
    const channel = get(channelMap, user[UserField.ID], undefined);
    return {
      ...user,
      rssUrl: channel
        ? `${process.env.REACT_APP_RSS_URL}/${channel[ChannelField.ID]}`
        : "",
    };
  });
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, "users");
  });
};

export default UserExporter;
