import { createContext, ReactNode, useContext, useState } from "react";
import keyBy from "lodash/keyBy";
import { ChannelField, IChannel } from "../models/entities";
import { getChannelByUserIds } from "../services/firebase/channel";

interface ChannelMap {
  [key: string]: IChannel | undefined;
}

type AppContextData = {
  channels: ChannelMap;
  isLoadingChannels: boolean;
  loadChannelData: (ids: string[]) => void;
};

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContext = createContext({} as AppContextData);

export function AppContextProvider({ children }: AppContextProviderProps) {
  const [channels, setChannels] = useState<ChannelMap>({});
  const [isLoadingChannels, setIsLoadingChannels] = useState(false);

  const loadChannelData = async (ids: string[]) => {
    setIsLoadingChannels(true);
    try {
      const res = await getChannelByUserIds(ids);
      setChannels({
        ...channels,
        ...keyBy(res, ChannelField.USER_ID),
      });
    } catch (err) {
      console.log("loadChannelData err", err);
    } finally {
      setIsLoadingChannels(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        channels,
        isLoadingChannels,
        loadChannelData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => {
  return useContext(AppContext);
};
