import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";

export const forceRenewExternalRss = async (id: string) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "forceRenewExternalRss"
    )({ id });
    return data.result;
  } catch {
    throw new Error("Failed to force renew external rss feed");
  }
};
