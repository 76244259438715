import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";

export const deleteExternalRssSource = async (id: string) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "deleteExternalRssSource"
    )({ id });
    return data.result;
  } catch {
    throw new Error("Failed to remove external RSS");
  }
};
