import Box from "@mui/material/Box";
import RssFeedIcon from "@mui/icons-material/RssFeedRounded";
import AvatarField from "../../components/AvatarField";
import { IUser, UserField } from "../../models/entities";
import { PersonalInfoMapField } from "../../models/valueObjects";
import { useRecordContext } from "react-admin";

const UserAvatarField = () => {
  const record = useRecordContext<IUser>();

  return (
    <Box position="relative">
      <AvatarField
        source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.AVATAR}`}
        size={32}
      />
      {record[UserField.IS_EXTERNAL_RSS_ACCOUNT] && (
        <Box
          border="1px solid white"
          position="absolute"
          bottom={-3}
          right={-3}
          width={16}
          height={16}
          borderRadius={9}
          fontSize={12}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#73DA23" }}
        >
          <RssFeedIcon color="inherit" fontSize="inherit" />
        </Box>
      )}
    </Box>
  );
};

export default UserAvatarField;
