import { styled, StepLabel, Box } from "@mui/material";

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  ".MuiStepLabel-iconContainer .MuiSvgIcon-root": {
    fontSize: "42px",
  },
  ".MuiStepLabel-labelContainer .MuiStepLabel-label": {
    fontSize: "18px",
  },
  ".MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active": {
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
}));

export const PreviewItemContainer = styled(Box)`
  border: 1px solid #dadce0;
  border-radius: 14px;
`;
