export function getDurationString(milliseconds: number): string {
  const valueInSeconds = milliseconds / 1000;

  const hours = Math.floor(valueInSeconds / 3600);
  const minutes = Math.floor((valueInSeconds % 3600) / 60);
  const seconds = Math.floor(valueInSeconds % 60);

  const timeString = (
    hours > 0 ? [hours, minutes, seconds] : [minutes, seconds]
  )
    .map((unit) => String(unit).padStart(2, "0"))
    .join(":");

  return timeString;
}
