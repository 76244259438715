import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { Collection } from "../../models/constants";
import { IUser, UserField } from "../../models/entities";
import { firestore } from "./clientApp";

export const userCollection = collection(firestore, Collection.USERS);

export const getUserById = async (id: string): Promise<IUser | undefined> => {
  const ref = doc(firestore, Collection.USERS, id);
  const snapshot = await getDoc(ref);
  if (snapshot.exists()) {
    return snapshot.data() as IUser;
  } else {
    return undefined;
  }
};

export const getUserByUsername = async (
  username: string
): Promise<IUser | undefined> => {
  const q = query(
    userCollection,
    where(UserField.USERNAME, "==", username),
    limit(1)
  );

  const res = await getDocs(q);
  return res.docs.length ? (res.docs[0].data() as IUser) : undefined;
};
