import { Timestamp } from "firebase/firestore";
import { IAudioMap } from "../valueObjects";
import { TEpisodeProcessStatus, TEpisodeVisibility } from "./episode";

export enum BestOfEpisodeField {
  ID = "id",
  AUDIO = "audio",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  USER_ID = "userId",
  PROCESS_STATUS = "processStatus",
  VISIBILITY = "visibility",
  PUBLISHED_AT = "publishedAt",
  TITLE = "title",
  DESCRIPTION = "description",
  IS_EXPLICIT = "isExplicit",
  VIEW_COUNT = "viewCount",
  IS_DELETED = "isDeleted",
}

export interface IBestOfEpisode {
  [BestOfEpisodeField.ID]: string;
  [BestOfEpisodeField.AUDIO]?: IAudioMap;
  [BestOfEpisodeField.CREATED_AT]?: Timestamp;
  [BestOfEpisodeField.UPDATED_AT]?: Timestamp;
  [BestOfEpisodeField.USER_ID]?: string;
  [BestOfEpisodeField.PROCESS_STATUS]?: TEpisodeProcessStatus;
  [BestOfEpisodeField.VISIBILITY]?: TEpisodeVisibility;
  [BestOfEpisodeField.PUBLISHED_AT]?: Timestamp;
  [BestOfEpisodeField.TITLE]?: string;
  [BestOfEpisodeField.DESCRIPTION]?: string;
  [BestOfEpisodeField.IS_EXPLICIT]?: boolean;
  [BestOfEpisodeField.VIEW_COUNT]?: number;
  [BestOfEpisodeField.IS_DELETED]?: boolean;
}
