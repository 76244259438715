import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const InfomationRow = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => (
  <Stack flexDirection={{ xs: "column", md: "row" }}>
    <Typography fontWeight={600} color="text.secondary" minWidth={200}>
      {label}
    </Typography>
    {children}
  </Stack>
);

export default InfomationRow;
