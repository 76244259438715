import { useNotify } from "react-admin";
import TrueIcon from "@mui/icons-material/Verified";
import FalseIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { ChannelField, IChannel } from "../../models/entities/channel";

interface RssStatusIconProps {
  channel: IChannel;
}

export const RssStatusIcon = ({ channel }: RssStatusIconProps) => {
  const notify = useNotify();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {!!channel?.[ChannelField.IS_VERIFIED] ? (
        <Tooltip
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_RSS_URL}/${channel.id}`
            );
            notify("Copied RSS link to clipboard");
          }}
          title="Copy RSS url"
          sx={{ cursor: "pointer" }}
        >
          <TrueIcon color="success" />
        </Tooltip>
      ) : (
        <Tooltip title="Not completed setup channel">
          <FalseIcon color="error" />
        </Tooltip>
      )}
    </Box>
  );
};

export default RssStatusIcon;
