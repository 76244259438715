import { doc, getDoc } from "firebase/firestore";
import { Collection } from "../../models/constants";
import { IPublicUserInfo } from "../../models/entities";
import { firestore } from "./clientApp";

export const getPublicUserInfoById = async (
  id: string
): Promise<IPublicUserInfo | undefined> => {
  const ref = doc(firestore, Collection.PUBLIC_USER_INFOS, id);
  const snapshot = await getDoc(ref);
  if (snapshot.exists()) {
    return snapshot.data() as IPublicUserInfo;
  } else {
    return undefined;
  }
};
