import { IPlayerMap, PlayerMap } from './playerMap';

export enum UserSettingMapField {
  PLAYER = 'player',
}

export interface IUserSettingMap {
  [UserSettingMapField.PLAYER]?: IPlayerMap;
}
/**
 * User setting map class
 */
export class UserSettingMap {
  /**
   *  @return {UserSettingMap} Default UserSettingMap value
   */
  static default = (): UserSettingMap => {
    return {
      [UserSettingMapField.PLAYER]: PlayerMap.default(),
    };
  };
}
