import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { scaleLinear } from "d3-scale";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { alpha, colors, useTheme } from "@mui/material";
import get from "lodash/get";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDoughnutProps {
  data: { value: number; label: string; percent: number }[];
  loading: boolean;
}

const DoughnutChart = ({ data, loading }: IDoughnutProps) => {
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
  const { palette } = useTheme();
  const isNoData = data.length === 0;

  useEffect(() => {
    const colorGenerator = scaleLinear<string>()
      .domain([0, data.length / 2, data.length - 1])
      .range(["#115f9a", "#ffd700", "#e60049"]);

    setBackgroundColors(
      data.map((el, index) => {
        return colorGenerator(index);
      })
    );
  }, [data, palette.primary.main]);

  const chartData = {
    labels: data.map((el) => el.label),
    datasets: [
      {
        data: !isNoData ? data.map((el) => el.value) : [-1],
        backgroundColor: !isNoData
          ? backgroundColors
          : alpha(palette.primary.main, 0.05),
        spacing: isNoData ? 0 : undefined,
        borderWidth: isNoData ? 0 : undefined,
      },
    ],
  };

  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={270}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Grid container>
      <Grid item xs={5}>
        <Box height="100%" display="flex" alignItems="center">
          <Doughnut
            plugins={[
              {
                id: "total",
                beforeDraw: (chart) => {
                  const datas: number[] = get(
                    chart.data.datasets,
                    "0.data",
                    []
                  );
                  const total = datas.reduce((sum, el) => sum + el, 0);

                  const width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                  ctx.restore();
                  ctx.textBaseline = "middle";

                  if (total === -1) {
                    ctx.font = `${(height / 180).toFixed(2)}em sans-serif`;
                    ctx.fillStyle = palette.text.secondary;
                    const labelSize = ctx.measureText("No data");
                    ctx.fillText(
                      "No data",
                      Math.round(width - labelSize.width) / 2,
                      height / 2
                    );
                  } else {
                    ctx.font = `bold ${(height / 100).toFixed(2)}em sans-serif`;
                    ctx.fillStyle = palette.primary.main;
                    const totalSize = ctx.measureText(total.toString());
                    ctx.fillText(
                      total.toString(),
                      Math.round(width - totalSize.width) / 2,
                      height / 2
                    );

                    ctx.font = `${(height / 180).toFixed(2)}em sans-serif`;
                    ctx.fillStyle = palette.text.secondary;
                    const labelSize = ctx.measureText("Total");
                    ctx.fillText(
                      "Total",
                      Math.round(width - labelSize.width) / 2,
                      height / 2 - totalSize.actualBoundingBoxAscent * 2
                    );
                  }

                  ctx.save();
                },
              },
            ]}
            options={{
              cutout: "75%",
              plugins: {
                legend: { display: false },
                tooltip: {
                  enabled: !isNoData,
                  callbacks: {
                    title: (tooltipItems) => tooltipItems[0]["label"],
                    label: (tooltipItem) =>
                      `${tooltipItem["raw"]} (${
                        data[tooltipItem.dataIndex].percent
                      }%)`,
                  },
                  borderWidth: 0.15,
                  borderColor: "black",
                  displayColors: false,
                  backgroundColor: "white",
                  titleColor: palette.text.primary,
                  bodyColor: palette.text.secondary,
                  titleFont: { size: 13 },
                },
              },
            }}
            data={chartData}
          />
        </Box>
      </Grid>
      <Grid item xs={7} height={270} paddingLeft={2} paddingY={4}>
        <Box height="100%" overflow="auto" paddingRight={2}>
          {data.map((d, index) => (
            <Stack
              key={index}
              fontSize={13}
              flexDirection="row"
              justifyContent="space-between"
              position="relative"
              paddingY={1.5}
              marginY={1}
              order={d.value}
            >
              <span style={{ fontWeight: 600 }}>{d.label}</span>
              <span style={{ color: palette.text.disabled, flexShrink: 0 }}>
                {d.value} / {d.percent}%
              </span>
              <Box
                left={0}
                bottom={0}
                height={5}
                width="100%"
                overflow="hidden"
                borderRadius={2.5}
                position="absolute"
                sx={{ backgroundColor: colors.purple[50] }}
              >
                <Box
                  height={5}
                  width={`${d.percent}%`}
                  sx={{ backgroundColor: backgroundColors[index] }}
                />
              </Box>
            </Stack>
          ))}
          {data.length === 0 && (
            <Stack
              fontSize={13}
              flexDirection="row"
              justifyContent="space-between"
              position="relative"
              paddingY={1.5}
              marginY={1}
            >
              <span style={{ color: palette.text.disabled, fontWeight: 400 }}>
                No data
              </span>
              <span style={{ color: palette.text.disabled, flexShrink: 0 }}>
                0
              </span>
              <Box
                left={0}
                bottom={0}
                height={5}
                width="100%"
                overflow="hidden"
                borderRadius={2.5}
                position="absolute"
                sx={{ backgroundColor: colors.purple[50] }}
              />
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DoughnutChart;
