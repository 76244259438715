import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IEpisode } from "../models/entities";

type PlayerContextData = {
  playlist: Array<IEpisode>;
  currentEpisodeIndex: number;
  isPlaying: boolean;
  isLooping: boolean;
  playEpisodes: (list: IEpisode[], index?: number) => void;
  togglePlay: () => void;
  setPlayingState: (state: boolean) => void;
  playNext: () => void;
  playPrevious: () => void;
  toggleLoop: () => void;
  clearPlayerState: () => void;
  progress: number;
  setProgress: (value: number) => void;
  audioRef: React.RefObject<HTMLAudioElement>;
  onEpisodeEnded: () => void;
  setupPlayer: () => void;
};

type PlayerContextProviderProps = {
  children: ReactNode;
};

export const PlayerContext = createContext({} as PlayerContextData);

export function PlayerContextProvider({
  children,
}: PlayerContextProviderProps) {
  const [playlist, setPlaylist] = useState<IEpisode[]>([]);
  const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLooping, setIsLooping] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      audioRef.current.play().catch(() => setIsPlaying(false));
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  const onEpisodeEnded = () => {
    if (isLooping && audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else if (currentEpisodeIndex + 1 < playlist.length) {
      setCurrentEpisodeIndex(currentEpisodeIndex + 1);
    } else {
      setIsPlaying(false);
    }
  };

  const setupPlayer = () => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.currentTime = 0;
    if (isPlaying) {
      audioRef.current.play().catch(() => console.log("play episode failed"));
    }

    audioRef.current.addEventListener("timeupdate", () => {
      if (audioRef?.current) {
        setProgress(audioRef.current.currentTime * 1000);
      }
    });
  };

  function playEpisodes(list: IEpisode[], index = 0) {
    setPlaylist(list);
    setCurrentEpisodeIndex(index);
    setIsPlaying(true);
  }

  function togglePlay() {
    setIsPlaying(!isPlaying);
  }

  function toggleLoop() {
    setIsLooping(!isLooping);
  }

  function setPlayingState(state: boolean) {
    setIsPlaying(state);
  }

  function clearPlayerState() {
    setPlaylist([]);
    setCurrentEpisodeIndex(0);
  }

  function playNext() {
    if (currentEpisodeIndex + 1 < playlist.length) {
      setCurrentEpisodeIndex(currentEpisodeIndex + 1);
    }
  }

  function playPrevious() {
    if (currentEpisodeIndex > 0) {
      setCurrentEpisodeIndex(currentEpisodeIndex - 1);
    } else if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  }

  return (
    <PlayerContext.Provider
      value={{
        currentEpisodeIndex,
        playlist,
        isPlaying,
        togglePlay,
        setPlayingState,
        playEpisodes,
        playNext,
        playPrevious,
        isLooping,
        toggleLoop,
        clearPlayerState,
        progress,
        setProgress,
        audioRef,
        onEpisodeEnded,
        setupPlayer,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}

export const usePlayerContext = () => {
  return useContext(PlayerContext);
};
