import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";

export const addExternalRssSource = async (url: string, username: string) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "addExternalRssSource"
    )({ url, username });
    return data.result;
  } catch {
    throw new Error("Failed to add external rss source");
  }
};
