import React, { useState } from "react";
import {
  FieldProps,
  useNotify,
  useRecordContext,
  useUpdate,
} from "react-admin";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IEpisode,
  EpisodeField,
  BestOfEpisodeField,
  EpisodeVisibility,
} from "../../models/entities";
import EpisodeEditDialog from "../../components/EpisodeEditDialog";
import { Collection } from "../../models/constants";
import { getDraftBestOfEpisode } from "../../services/firebase/bestOfEpisode";
import ConfirmDialog from "../../components/ConfirmDialog";
import { addToBestOf } from "../../services/firebase/functions/addToBestOf";

const EpisodeRowActions = (props: FieldProps) => {
  const episode = useRecordContext<IEpisode>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCreateBestOfShow, setOpenCreateBestOfShow] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogDescription, setConfirmDialogDescription] = useState("");
  const [handleUpdate, { isLoading: useUpdateLoading }] = useUpdate();
  const notify = useNotify();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditEpisode = async (title: string, description: string) => {
    try {
      await handleUpdate(Collection.EPISODES, {
        id: episode.id,
        data: {
          [EpisodeField.TITLE]: title,
          [EpisodeField.DESCRIPTION]: description,
          [EpisodeField.UPDATED_AT]: new Date(),
        },
        previousData: episode,
      });
      notify("Update episode successfully", { type: "success" });
    } catch (e) {
      console.log(e);
      notify((e as Error).message, { type: "error" });
    }
    setOpenEdit(false);
  };

  const onConfirmAction = () => {
    handleAddToBestOf();
  };

  const handleAddToBestOf = async (title?: string, description?: string) => {
    setLoading(true);
    try {
      await addToBestOf(
        episode[EpisodeField.ID],
        title || description
          ? {
              title,
              description,
            }
          : undefined
      );
      notify(`Add this episode to "Best Of" successfully`, { type: "success" });
    } catch (e) {
      notify((e as Error).message, { type: "error" });
    }
    setLoading(false);
    setOpenConfirmDialog(false);
    setOpenCreateBestOfShow(false);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          disabled={
            episode[EpisodeField.VISIBILITY] !== EpisodeVisibility.PUBLIC
          }
          onClick={async () => {
            const currentBestOfEpisode = await getDraftBestOfEpisode();
            if (!currentBestOfEpisode) {
              setOpenCreateBestOfShow(true);
            } else {
              setOpenConfirmDialog(true);
              setConfirmDialogDescription(
                `Add this episode to "${
                  currentBestOfEpisode[BestOfEpisodeField.TITLE]
                }"?`
              );
            }
            handleClose();
          }}
        >
          Add to "Best of"
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenEdit(true);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
      </Menu>
      <EpisodeEditDialog
        open={openEdit}
        loading={useUpdateLoading}
        onOk={handleEditEpisode}
        onClose={() => setOpenEdit(false)}
        defaultTitle={episode[EpisodeField.TITLE]}
        defaultDescription={episode[EpisodeField.DESCRIPTION]}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        loading={loading}
        handleClose={() => setOpenConfirmDialog(false)}
        handleOk={onConfirmAction}
        title="Confirm"
        description={confirmDialogDescription}
      />
      <EpisodeEditDialog
        open={openCreateBestOfShow}
        loading={loading}
        onOk={handleAddToBestOf}
        onClose={() => setOpenCreateBestOfShow(false)}
        defaultTitle=""
        defaultDescription=""
        dialogTitle={`Create "Best of Show"`}
        dialogDescription={`Fill out this form to create a new "Best of Show"`}
      />
    </Box>
  );
};

export default EpisodeRowActions;
