import { Timestamp } from "firebase/firestore";
import { IMailchimpMap } from "../valueObjects";

export enum MailchimpDataField {
  CREATED_AT = "createdAt",
  ID = "id",
  MAILCHIMP = "mailchimp",
  UPDATED_AT = "updatedAt",
}

export interface IMailchimpData {
  [MailchimpDataField.CREATED_AT]?: Timestamp;
  [MailchimpDataField.ID]: string;
  [MailchimpDataField.MAILCHIMP]?: IMailchimpMap;
  [MailchimpDataField.UPDATED_AT]?: Timestamp;
}
