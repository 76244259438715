export enum Collection {
  CHANNELS = "channels",
  EPISODE_COLLABORATORS = "episodeCollaborators",
  CREATOR_DAILY_SUMMARY_DATA = "creatorDailySummaryData",
  EPISODES = "episodes",
  EPISODE_DAILY_SUMMARY_DATA = "episodeDailySummaryData",
  EPISODE_EVENTS = "episodeEvents",
  EPISODE_MESSAGES = "episodeMessages",
  NOTIFICATIONS = "notifications",
  PUBLIC_USER_INFOS = "publicUserInfos",
  TIKTOK_IMPORT_QUEUES = "tiktokImportQueues",
  TIMELINE = "timeline",
  USERS = "users",
  USER_BLOCKERS = "userBlockers",
  USER_FOLLOWERS = "userFollowers",
  EPISODE_USER_ACTIONS = "episodeUserActions",
  APP_CONFIG = "appConfig",
  MAILCHIMP_DATA = "mailchimpData",
  EXTERNAL_RSS_SOURCES = "externalRssSources",
  BEST_OF_EPISODES = "bestOfEpisodes",
}
