import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import {
  ExternalRssFeedMapField,
  IExternalRssFeedMap,
} from "../../models/valueObjects";
import ImportRssPreviewList from "./ImportRssPreviewList";
import ImportRssPreviewFeedData from "./ImportRssPreviewFeedData";

interface ImportRssStep2Props {
  handleGoBack: () => void;
  moveToNextStep: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  feed?: IExternalRssFeedMap;
}

const ImportRssStep2 = ({
  handleGoBack: handleBack,
  moveToNextStep,
  loading,
  setLoading,
  feed,
}: ImportRssStep2Props) => {
  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          marginTop: 6,
          marginBottom: 8,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={6}>
            <Grid item xs={6} md={4}>
              <ImportRssPreviewFeedData
                title={feed?.[ExternalRssFeedMapField.TITLE] || ""}
                description={feed?.[ExternalRssFeedMapField.DESCRIPTION] || ""}
                img={feed?.[ExternalRssFeedMapField.IMAGE] || ""}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <ImportRssPreviewList
                items={feed?.[ExternalRssFeedMapField.ITEMS] || []}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" disabled={loading} onClick={handleBack}>
          Back
        </Button>
        <Button
          size="large"
          disabled={loading}
          onClick={moveToNextStep}
          autoFocus
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default ImportRssStep2;
