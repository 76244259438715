import { Timestamp } from "firebase/firestore";
import { ICategoryMap } from "../valueObjects/categoryMap";

export enum ChannelField {
  ID = "id",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  USER_ID = "userId",
  HASHTAGS = "hashtags",
  RSS_URL = "rssUrl",
  WEBSITE_URL = "websiteUrl",
  LANGUAGE = "language",
  CATEGORIES = "categories",
  PUBLISHER_EMAIL = "publisherEmail",
  PUBLISHER_NAME = "publisherName",
  THUMBNAIL = "thumbnail",
  DESCRIPTION = "description",
  SLUG = "slug",
  TITLE = "title",
  IS_EXPLICIT = "isExplicit",
  IS_VERIFIED = "isVerified",
  COPYRIGHT = "copyright",
}

export interface IChannel {
  [ChannelField.ID]?: string;
  [ChannelField.CREATED_AT]?: Timestamp;
  [ChannelField.UPDATED_AT]?: Timestamp;
  [ChannelField.USER_ID]?: string;
  [ChannelField.HASHTAGS]?: string[];
  [ChannelField.RSS_URL]?: string;
  [ChannelField.WEBSITE_URL]?: string;
  [ChannelField.LANGUAGE]?: string;
  [ChannelField.CATEGORIES]?: ICategoryMap[];
  [ChannelField.PUBLISHER_EMAIL]?: string;
  [ChannelField.PUBLISHER_NAME]?: string;
  [ChannelField.THUMBNAIL]?: string;
  [ChannelField.DESCRIPTION]?: string;
  [ChannelField.SLUG]?: string;
  [ChannelField.TITLE]?: string;
  [ChannelField.IS_EXPLICIT]?: boolean;
  [ChannelField.IS_VERIFIED]?: boolean;
  [ChannelField.COPYRIGHT]?: string;
}
