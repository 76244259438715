import {
  startOfMonth,
  isSameMonth,
  isSameDay,
  addMonths,
  differenceInMonths,
} from "date-fns";
import { IStatisticData, ISummaryData, IViewCountData } from "./types";
import { useCallback, useState, useEffect } from "react";
import { defineds, getDateFromToday } from "../utils";
import { getBestOfEpisodesStatistic } from "../services/firebase/functions/getBestOfEpisodesStatistic";
import { getBestOfChannelStatistic } from "../services/firebase/functions/getBestOfChannelStatistic";
import { Timestamp } from "firebase/firestore";

const generateDefaultDatas = (
  from: Date,
  to: Date,
  isSelectMonth: boolean
): IViewCountData[] => {
  const result: IViewCountData[] = [];
  if (isSelectMonth) {
    const end = startOfMonth(new Date(to.getTime()));
    end.setMonth(end.getMonth() + 1);
    for (
      let m = startOfMonth(new Date(from.getTime()));
      !isSameMonth(m, end);
      m.setMonth(m.getMonth() + 1)
    ) {
      result.push({
        time: Timestamp.fromMillis(m.getTime()),
        viewCount: 0,
      });
    }
  } else {
    const end = new Date(to.getTime());
    end.setDate(end.getDate() + 1);
    for (
      let d = new Date(from.getTime());
      !isSameDay(d, end);
      d.setDate(d.getDate() + 1)
    ) {
      result.push({
        time: Timestamp.fromMillis(d.getTime()),
        viewCount: 0,
      });
    }
  }
  return result;
};

const useStatisticData = (): IStatisticData => {
  const [loadingStatsData, setLoadingStatsData] = useState(false);
  const [chartData, setChartData] = useState<IViewCountData[]>([]);
  const [platforms, setPlatformsData] = useState<ISummaryData[]>([]);
  const [countries, setCountriesData] = useState<ISummaryData[]>([]);
  const [os, setOsData] = useState<ISummaryData[]>([]);
  const [episodeStats, setEpisodeStats] = useState([]);
  const [loadingEpisodeStats, setLoadingEpisodeStats] = useState(false);
  const [from, setFrom] = useState<Date | undefined>(getDateFromToday(-29));
  const [to, setTo] = useState<Date | undefined>(defineds.endOfToday);
  const isSelectMonth =
    !from ||
    !to ||
    Boolean(
      isSameDay(from, startOfMonth(addMonths(new Date(), -11))) &&
        isSameDay(to, defineds.endOfToday)
    );

  const fetchViewCountStats = useCallback(async () => {
    setLoadingStatsData(true);
    try {
      const result: {
        platforms: ISummaryData[];
        os: ISummaryData[];
        countries: ISummaryData[];
        viewCounts: IViewCountData[];
      } = await getBestOfChannelStatistic(
        from?.getTime(),
        to?.getTime(),
        isSelectMonth ? "monthly" : "daily"
      );
      setCountriesData(result.countries);
      setPlatformsData(result.platforms);
      setOsData(result.os);
      let fromDate =
        from ??
        new Date(
          Math.min(...result.viewCounts.map((el) => el.time.toMillis()))
        );
      if (!from && differenceInMonths(defineds.startOfToday, fromDate) < 11) {
        fromDate = addMonths(new Date(), -11);
      }

      const temp = generateDefaultDatas(
        fromDate,
        to || defineds.startOfToday,
        isSelectMonth
      );

      for (const el of result.viewCounts) {
        const date = el.time.toDate();
        const index = temp.findIndex((obj) =>
          isSelectMonth
            ? isSameMonth(obj.time.toDate(), date)
            : isSameDay(obj.time.toDate(), date)
        );
        if (index !== -1) {
          temp[index].viewCount = el.viewCount;
        }
      }
      setChartData(temp);
    } catch (e) {
      console.log((e as Error).message);
    } finally {
      setLoadingStatsData(false);
    }
  }, [from, isSelectMonth, to]);

  const fetchEpisodeStats = useCallback(async () => {
    setLoadingEpisodeStats(true);
    try {
      const res = await getBestOfEpisodesStatistic(
        from?.getTime(),
        to?.getTime(),
        isSelectMonth ? "monthly" : "daily"
      );
      setEpisodeStats(res);
    } catch (e) {
      console.log((e as Error).message);
    } finally {
      setLoadingEpisodeStats(false);
    }
  }, [from, isSelectMonth, to]);

  useEffect(() => {
    fetchViewCountStats();
    fetchEpisodeStats();
  }, [fetchEpisodeStats, fetchViewCountStats]);

  return {
    chartData,
    platforms,
    countries,
    os,
    loadingStatsData,
    episodeStats,
    loadingEpisodeStats,
    from,
    setFrom,
    to,
    setTo,
    isSelectMonth,
  };
};

export default useStatisticData;
