import { UseRecordContextParams, useRecordContext } from "react-admin";
import { EpisodeField, IEpisode } from "../../models/entities";

export const LikePerViewField = (props: UseRecordContextParams<IEpisode>) => {
  const record = useRecordContext(props);
  const percentage =
    record[EpisodeField.VIEWS_COUNT] && record[EpisodeField.LIKES_COUNT]
      ? Math.round(
          (record[EpisodeField.LIKES_COUNT] /
            record[EpisodeField.VIEWS_COUNT]) *
            10000
        ) / 100
      : 0;
  return record ? <span>{percentage} %</span> : null;
};

export default LikePerViewField;
