import { useRecordContext, FieldProps } from "react-admin";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { IUser, UserField, UserRole } from "../../models/entities";
import { PersonalInfoMapField } from "../../models/valueObjects";
import AvatarField from "../../components/AvatarField";
import { uppercaseFirstLetter } from "../../utils";
import UserInfomationActions from "./UserInfomationActions";

const UserInfomation = (props: FieldProps) => {
  const user = useRecordContext<IUser>();

  if (!user) return null;
  return (
    <Stack paddingX={6} paddingY={4} gap={3}>
      <Box display="flex" flexDirection="row" gap={4} alignItems="center">
        <AvatarField
          size={140}
          source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.AVATAR}`}
        />
        <Stack flex={1}>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography fontSize={28} fontWeight={600}>
              {user[UserField.PERSONAL_INFO][PersonalInfoMapField.FULL_NAME]}
            </Typography>
            <Chip
              size="small"
              label={uppercaseFirstLetter(
                user[UserField.ROLE] || UserRole.USER
              )}
              color="info"
            />
            {!!user[UserField.ACCOUNT_LINKING]?.tiktok && (
              <Chip
                size="small"
                label="Authorized with TikTok"
                color="success"
              />
            )}
            {!!user[UserField.IS_EXTERNAL_RSS_ACCOUNT] && (
              <Chip size="small" label="External Rss" color="success" />
            )}
          </Box>
          <Typography variant="subtitle1" color="text.secondary">
            @{user[UserField.USERNAME]}
          </Typography>
          <Link href={`mailto:${user[UserField.EMAIL]}`} variant="body1">
            {user[UserField.EMAIL]}
          </Link>
        </Stack>
        <UserInfomationActions />
      </Box>
      {user[UserField.PERSONAL_INFO][PersonalInfoMapField.BIO] && (
        <Typography fontSize={16} color="text.secondary">
          {user[UserField.PERSONAL_INFO][PersonalInfoMapField.BIO]}
        </Typography>
      )}
      <Stack flexDirection="row" gap={4} bgcolor="#f6f7f9" borderRadius={2}>
        <Stack paddingY={2.5} flex={1} alignItems="center">
          <Typography fontSize={26} fontWeight={600} color="text.secondary">
            {user[UserField.EPISODE_COUNT]}
          </Typography>
          <Typography fontWeight={600} color="text.disabled">
            Shortcasts
          </Typography>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ margin: "28px 0" }}
        />
        <Stack paddingX={4} paddingY={2} flex={1} alignItems="center">
          <Typography fontSize={26} fontWeight={600} color="text.secondary">
            {user[UserField.FOLLOWER_COUNT]}
          </Typography>
          <Typography fontWeight={600} color="text.disabled">
            Followers
          </Typography>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ margin: "28px 0" }}
        />
        <Stack paddingX={4} paddingY={2} flex={1} alignItems="center">
          <Typography fontSize={26} fontWeight={600} color="text.secondary">
            {user[UserField.FOLLOWING_COUNT]}
          </Typography>
          <Typography fontWeight={600} color="text.disabled">
            Following
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default UserInfomation;
