import { Timestamp } from "firebase/firestore";
import {
  ITiktokAccountLinkingMap,
  IUserDeviceDataMap,
  IPersonalInfoMap,
  IUserSettingMap,
  IRssDataMap,
} from "../valueObjects";

export enum UserField {
  ID = "id",
  USERNAME = "username",
  EMAIL = "email",
  PERSONAL_INFO = "personalInfo",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  SETTINGS = "settings",
  ACCOUNT_LINKING = "accountLinking",
  USER_DEVICES = "userDevices",
  RSS_DATA = "rssData",
  LAST_LOGIN_AT = "lastLoginAt",
  LAST_OPENED_AT = "lastOpenedAt",
  EPISODE_COUNT = "episodeCount",
  FOLLOWER_COUNT = "followerCount",
  FOLLOWING_COUNT = "followingCount",
  TIMEZONE = "timezone",
  IS_PRIVATE = "isPrivate",
  IS_DELETED = "isDeleted",
  REQUESTED_TO_DELETE_AT = "requestedToDeleteAt",
  ROLE = "role",
  SHOW_ON_DISCOVER = "showOnDiscover",
  IS_EXTERNAL_RSS_ACCOUNT = "isExternalRssAccount",
  SHOW_ON_DEFAULT_SEARCH_LIST = "showOnDefaultSearchList",
}

export interface IAccountLinkingMap {
  tiktok?: ITiktokAccountLinkingMap;
}

interface IUserDeviceMap {
  [key: string]: IUserDeviceDataMap;
}

export enum UserRole {
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  USER = "USER",
  ANALYST = "ANALYST",
}

export type TUserRole = (typeof UserRole)[keyof typeof UserRole];

export interface IUser {
  [UserField.ID]: string;
  [UserField.USERNAME]?: string;
  [UserField.EMAIL]?: string;
  [UserField.PERSONAL_INFO]: IPersonalInfoMap;
  [UserField.CREATED_AT]?: Timestamp;
  [UserField.UPDATED_AT]?: Timestamp;
  [UserField.SETTINGS]?: IUserSettingMap;
  [UserField.ACCOUNT_LINKING]?: IAccountLinkingMap | null;
  [UserField.USER_DEVICES]?: IUserDeviceMap;
  [UserField.RSS_DATA]?: IRssDataMap;
  [UserField.LAST_LOGIN_AT]?: Timestamp;
  [UserField.LAST_OPENED_AT]?: Timestamp;
  [UserField.EPISODE_COUNT]?: number;
  [UserField.FOLLOWER_COUNT]?: number;
  [UserField.FOLLOWING_COUNT]?: number;
  [UserField.TIMEZONE]?: string;
  [UserField.IS_PRIVATE]?: boolean;
  [UserField.IS_DELETED]?: boolean;
  [UserField.REQUESTED_TO_DELETE_AT]?: Timestamp;
  [UserField.ROLE]?: TUserRole;
  [UserField.SHOW_ON_DISCOVER]?: boolean;
  [UserField.IS_EXTERNAL_RSS_ACCOUNT]?: boolean;
  [UserField.SHOW_ON_DEFAULT_SEARCH_LIST]?: boolean;
}
