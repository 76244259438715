import React from 'react';
import get from 'lodash/get';
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { alpha, colors } from '@mui/material';
import { scaleLinear } from 'd3-scale';
import { TableBodyCell } from './styles';
import { StatsTableRowProps } from './types';

const StatsTableRow = ({ columns, data, valueKey, total, index }: StatsTableRowProps) => {
  const { palette } = useTheme();
  const percent = valueKey
    ? Math.round(((get(data, valueKey, 0) as number) * 1000) / total) / 10
    : 0;

  return (
    <TableRow
      hover
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, position: 'relative' }}
    >
      {columns.map((col, i) => (
        <TableBodyCell key={`cell_${index}_${i}`} {...col.bodyCellProps}>
          <span>{get(data, col.key, '')}</span>
          {col.key === valueKey && (
            <span
              style={{
                display: 'inline-block',
                width: 50,
                color: palette.text.secondary,
              }}
            >{`${percent}%`}</span>
          )}
        </TableBodyCell>
      ))}
      <td>
        <Box
          left={0}
          bottom={0}
          height={5}
          width="100%"
          overflow="hidden"
          borderRadius={2.5}
          position="absolute"
          sx={{ backgroundColor: colors.purple[50] }}
        >
          <Box
            height={5}
            width={`${percent}%`}
            sx={{
              backgroundColor: alpha(
                palette.primary.main,
                scaleLinear().domain([0, 100]).range([0.5, 1])(percent)
              ),
            }}
          />
        </Box>
      </td>
    </TableRow>
  );
};

export default StatsTableRow;
