import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { LoadingStack } from "../../globals/styles";

interface EpisodeEditDialogProps {
  open: boolean;
  loading?: boolean;
  onOk: (title: string, description: string) => void;
  onClose: () => void;
  dialogTitle?: string;
  dialogDescription?: string;
  defaultTitle?: string;
  defaultDescription?: string;
}

const EpisodeEditDialog = ({
  open,
  onOk,
  onClose,
  dialogTitle = "Edit episode",
  dialogDescription = "Please fill out this form to edit this episode.",
  defaultTitle = "",
  defaultDescription = "",
  loading = false,
}: EpisodeEditDialogProps) => {
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);

  useEffect(() => {
    if (!open) {
      setTitle(defaultTitle);
      setDescription(defaultDescription);
    }
  }, [defaultDescription, defaultTitle, open]);

  return (
    <Dialog open={open} onClose={() => !loading && onClose()}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogDescription}</DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          multiline
          rows={4}
          margin="normal"
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!title || loading}
          onClick={() => onOk(title, description)}
        >
          Send
        </Button>
      </DialogActions>
      {loading && (
        <LoadingStack>
          <CircularProgress />
        </LoadingStack>
      )}
    </Dialog>
  );
};

export default EpisodeEditDialog;
