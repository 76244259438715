import { useState } from "react";
import {
  FieldProps,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExternalRssSourceField, IExternalRssSource } from "../models/entities";
import { deleteExternalRssSource } from "../services/firebase/functions/deleteExternalRssSource";
import ConfirmDialog from "../components/ConfirmDialog";

const DeleteExternalRssButton = (props: FieldProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const rssSource = useRecordContext<IExternalRssSource>(props);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteExternalRssSource(rssSource[ExternalRssSourceField.ID]!);
      setOpen(false);
      refresh();
      notify("Delete RSS successfully", { type: "success" });
    } catch (err) {
      notify((err as Error).message, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton color="error" onClick={(e) => setOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog
        open={open}
        loading={loading}
        handleClose={() => setOpen(false)}
        handleOk={handleDelete}
        title="Are you sure you want to remove this external RSS?"
        description="This action cannot be undone. All related content such as episodes
            and view statistics data will be removed."
      />
    </Box>
  );
};

export default DeleteExternalRssButton;
