import {
  FieldProps,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import Switch from "@mui/material/Switch";
import { Tooltip } from "@mui/material";

interface EditableBooleanFieldProps extends FieldProps {
  source: string;
  shouldDisabled?: (record: any) => boolean;
  editFinished?: () => void;
  disabledTooltip?: string;
}

const EditableBooleanField = (props: EditableBooleanFieldProps) => {
  const { source, shouldDisabled, editFinished, disabledTooltip = "" } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [handleUpdate, { isLoading }] = useUpdate();
  const disabled = shouldDisabled && shouldDisabled(record);

  return (
    <Tooltip title={disabled ? disabledTooltip : ""}>
      <span>
        <Switch
          onClick={(e) => e.stopPropagation()}
          disabled={disabled || isLoading}
          checked={!!record[source]}
          onChange={(e) => {
            handleUpdate(resource, {
              id: record.id,
              data: {
                [source]: e.target.checked,
              },
              previousData: record,
            });
            editFinished && editFinished();
          }}
        />
      </span>
    </Tooltip>
  );
};

export default EditableBooleanField;
