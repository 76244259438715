import { Button } from "react-admin";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingStack } from "../../globals/styles";

interface ConfirmDialogProps {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  handleOk: () => void;
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmDialog = ({
  open,
  loading,
  handleClose,
  handleOk,
  title,
  description,
  cancelText = "Cancel",
  confirmText = "Agree",
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={() => !loading && handleClose()}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={handleClose} label={cancelText} />
        <Button
          size="large"
          color="error"
          onClick={handleOk}
          label={confirmText}
          autoFocus
        />
      </DialogActions>
      {loading && (
        <LoadingStack>
          <CircularProgress />
        </LoadingStack>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
