import React from "react";
import { Route } from "react-router-dom";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { dataProvider, authProvider } from "./providers";
import EpisodeList from "./episodeManagement/EpisodeList";
import EpisodeIcon from "./episodeManagement/components/EpisodeIcon";
import ExternalRssIcon from "./externalRssManagement/ExternalRssIcon";
import ExternalRssList from "./externalRssManagement/ExternalRssList";
import { muiTheme } from "./theme";
import { Collection } from "./models/constants";
import Player from "./components/Player";
import UserList from "./userManagement/UserList";
import UserIcon from "./userManagement/UserIcon";
import StatisticDashboard from "./statistic";
import UserDetail from "./userManagement/UserDetail";
import EpisodeDetail from "./episodeManagement/EpisodeDetail";
import ListeningStats from "./listeningStats";
import { AdminLayout } from "./AdminLayout";

function App() {
  return (
    <React.Fragment>
      <Admin
        layout={AdminLayout}
        dashboard={StatisticDashboard}
        theme={muiTheme}
        title="Shortcast admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        {(permissions: string[]) => {
          return (
            <>
              {permissions.includes(Collection.USERS) && (
                <Resource
                  icon={UserIcon}
                  name={Collection.USERS}
                  options={{ label: "User management" }}
                  list={UserList}
                  show={UserDetail}
                />
              )}
              {permissions.includes(Collection.EPISODES) && (
                <Resource
                  icon={EpisodeIcon}
                  name={Collection.EPISODES}
                  options={{ label: "Episode management" }}
                  list={EpisodeList}
                  show={EpisodeDetail}
                />
              )}
              <CustomRoutes>
                <Route path="/best-of-stats" element={<ListeningStats />} />
              </CustomRoutes>
              {permissions.includes(Collection.EXTERNAL_RSS_SOURCES) && (
                <Resource
                  icon={ExternalRssIcon}
                  name={Collection.EXTERNAL_RSS_SOURCES}
                  options={{ label: "External RSS" }}
                  list={ExternalRssList}
                />
              )}
            </>
          );
        }}
      </Admin>
      <Player />
    </React.Fragment>
  );
}

export default App;
