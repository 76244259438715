import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: "lightgray",
          "& > th ": {
            color: "black",
            fontWeight: "bold",
          },
        },
      },
    },
  },
});
