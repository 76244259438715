import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";
import { IBestOfEpisode } from "../../../models/entities";

export const addToBestOf = async (
  episodeId: string,
  showData?: Partial<IBestOfEpisode>
) => {
  const { data }: { data: any } = await httpsCallable(
    functions,
    "addToBestOf"
  )({ episodeId, showData });
  return data.result;
};
