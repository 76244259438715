export enum AudioMapField {
  DURATION = 'duration',
  URL = 'url',
  PUBLIC_URL = 'publicUrl',
}

export interface IAudioMap {
  [AudioMapField.DURATION]?: number;
  [AudioMapField.URL]?: string;
  [AudioMapField.PUBLIC_URL]?: string;
}
