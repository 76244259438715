import { FirebaseDataProvider, RAFirebaseOptions } from "react-admin-firebase";
import isEmpty from "lodash/isEmpty";
import { firebaseConfig } from "../services/firebase/clientApp";
import { DataProvider, GetListParams } from "react-admin";
import { getEpisodeDiscoverTab } from "../services/firebase/episode";

const options: RAFirebaseOptions = {
  watch: [],
  logging: false,
  disableMeta: true,
  firestoreCostsLogger: {
    enabled: false,
  },
  lazyLoading: {
    enabled: true,
  },
};

const rafDataProvider = FirebaseDataProvider(firebaseConfig, options);

const removeSort = (params: GetListParams) => {
  const newParams = { ...params };

  newParams.sort = { field: "id", order: "ASC" };

  return newParams;
};

const customGetList = (resource: string, params: GetListParams) => {
  if (!!params.meta?.discoverFilter) {
    return getEpisodeDiscoverTab(params.pagination);
  } else {
    const newParams = !isEmpty(params.filter) ? removeSort(params) : params;
    return rafDataProvider.getList(resource, newParams);
  }
};

const dataProvider: DataProvider = {
  ...rafDataProvider,
  getList: customGetList,
};

export default dataProvider;
