import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { Collection } from "../../models/constants";
import {
  ExternalRssSourceField,
  IExternalRssSource,
} from "../../models/entities";
import { firestore } from "./clientApp";

export const externalRssSourceCollection = collection(
  firestore,
  Collection.EXTERNAL_RSS_SOURCES
);

/**
 * Get actions by episodeId
 * @param {string} url Rss url
 * @return {Promise<IExternalRssSource | undefined>} return the document if exist
 */
export const getExternalRssSourceByRssUrl = async (
  url: string
): Promise<IExternalRssSource | undefined> => {
  const q = query(
    externalRssSourceCollection,
    where(ExternalRssSourceField.RSS_URL, "==", url),
    limit(1)
  );

  const res = await getDocs(q);
  return res.docs.length
    ? (res.docs[0].data() as IExternalRssSource)
    : undefined;
};
