import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";
import { mapValuesDeep } from "../utils/mapValuesDeep";

export const getBestOfChannelStatistic = async (
  from?: number,
  to?: number,
  frequency = "daily"
) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "getBestOfChannelStatistic"
    )({ from, to, frequency });
    return mapValuesDeep(data.result);
  } catch {
    throw new Error("Failed to get statistic data");
  }
};
