import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";
import { mapValuesDeep } from "../utils/mapValuesDeep";

export const getUserEpisodes = async (
  userId: string,
  limit: number,
  cursor?: string
) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "getUserEpisodes"
    )({ userId, limit, cursor });
    if (!data.success || data.result === null)
      throw new Error("It was not possible to get user feeds");
    return mapValuesDeep(data.result);
  } catch {
    throw new Error("It was not possible to get user feeds");
  }
};
