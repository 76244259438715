import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { LoadingStack } from "../../globals/styles";

interface SendNotificationDialogProps {
  open: boolean;
  loading?: boolean;
  onSend: (title: string, body: string) => void;
  onClose: () => void;
  dialogTitle?: string;
  dialogDescription?: string;
  defaultTitle?: string;
  defaultBody?: string;
}

const SendNotificationDialog = ({
  open,
  onSend,
  onClose,
  dialogTitle = "Send notification",
  dialogDescription = "Please fill out this form to send notification to user.",
  defaultTitle = "Shortcast Club",
  defaultBody = "",
  loading = false,
}: SendNotificationDialogProps) => {
  const [title, setTitle] = useState(defaultTitle);
  const [body, setBody] = useState(defaultBody);

  useEffect(() => {
    if (!open) {
      setTitle(defaultTitle);
      setBody(defaultBody);
    }
  }, [defaultBody, defaultTitle, open]);

  return (
    <Dialog open={open} onClose={() => !loading && onClose()}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogDescription}</DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          multiline
          rows={4}
          margin="normal"
          label="Body"
          fullWidth
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          disabled={!title || !body || loading}
          onClick={() => onSend(title, body)}
        >
          Send
        </Button>
      </DialogActions>
      {loading && (
        <LoadingStack>
          <CircularProgress />
        </LoadingStack>
      )}
    </Dialog>
  );
};

export default SendNotificationDialog;
