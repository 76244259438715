import { useState } from "react";
import { Button, ButtonProps, useNotify, useRefresh } from "react-admin";
import AddIcon from "@mui/icons-material/Add";
import ImportRssDialog from "./ImportRssDialog";
import { addExternalRssSource } from "../../services/firebase/functions/addExternalRssSource";

const ImportRssButton = (props: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const onImportExternalRss = async (url: string, username: string) => {
    try {
      await addExternalRssSource(url, username);
      notify("Import RSS successfully", { type: "success" });
      refresh();
      return true;
    } catch {
      notify("Failed to import rss", { type: "error" });
      return false;
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        label="Import External RSS Source"
        {...props}
      >
        <AddIcon />
      </Button>
      <ImportRssDialog
        open={open}
        handleOk={onImportExternalRss}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ImportRssButton;
