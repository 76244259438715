import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

export const ListeningStatsContainer = styled(Box)`
  padding: 30px 96px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const StatsSection = styled(Box)`
  border-radius: 20px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`;

export const ScrollableBox = styled(Box)`
  width: 100%;
  overflow-x: auto;
`;

export const ChartBox = styled(Box)`
  position: relative;
  aspect-ratio: 2/1;
  min-width: 540px;
  height: auto;

  canvas {
    width: 100% !important;
  }
`;

export const StickyTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "header" && prop !== "footer",
})<{
  header?: boolean;
  footer?: boolean;
}>(({ theme, header, footer }) => {
  return {
    position: "sticky",
    left: 0,
    background: "inherit",
    zIndex: theme.zIndex.appBar + 1,
    borderRight: "1px solid #e0e0e0",
    ...(!!header && {
      color: theme.palette.text.disabled,
      fontWeight: 700,
      zIndex: theme.zIndex.appBar + 2,
    }),
    ...(!!footer && {
      color: theme.palette.text.primary,
      borderTop: "1px solid #e0e0e0",
      zIndex: theme.zIndex.appBar + 2,
      bottom: 0,
      fontSize: "0.875rem",
    }),
  };
});

export const TableHeadCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.disabled,
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(244,242,245)",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "white",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

export const TableFooterCell = styled(TableCell)(({ theme }) => ({
  borderTop: "1px solid #e0e0e0",
  position: "sticky",
  left: 0,
  bottom: 0,
  background: "inherit",
  color: theme.palette.text.primary,
  fontSize: "0.875rem",
}));
