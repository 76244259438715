export enum PlayerMapField {
  AUTO_PLAY = 'autoPlay',
}

export interface IPlayerMap {
  [PlayerMapField.AUTO_PLAY]?: boolean;
}

/**
 *  PlayerMap class
 */
export class PlayerMap {
  /**
   *  @return {PlayerMap} Default PlayerMap value
   */
  static default = (): PlayerMap => {
    return {
      [PlayerMapField.AUTO_PLAY]: true,
    };
  };
}
