import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  useRecordContext,
  FieldProps,
  FunctionField,
} from "react-admin";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TrueIcon from "@mui/icons-material/Verified";
import FalseIcon from "@mui/icons-material/Clear";
import {
  EpisodeField,
  EpisodeType,
  IEpisode,
  IUser,
  UserField,
} from "../models/entities";
import { PersonalInfoMapField } from "../models/valueObjects";
import { Collection } from "../models/constants";
import EpisodeInfomation from "./EpisodeInfomation";
import InfomationRow from "../components/InfomationRow";
import format from "date-fns/format";
import { uppercaseFirstLetter } from "../utils";

const EpisodeDetailTitle = () => {
  const episode = useRecordContext<IEpisode>();

  if (!episode) return null;
  return <span>Episode "{episode[EpisodeField.TITLE]}"</span>;
};

const Detail = (props: FieldProps) => {
  const episode = useRecordContext<IEpisode>();
  console.log(episode);
  return (
    <Stack gap={2} padding={2} mb={2}>
      <InfomationRow label="Visibility">
        <Typography variant="body1">
          {uppercaseFirstLetter(episode[EpisodeField.VISIBILITY] || "")}
        </Typography>
      </InfomationRow>
      <InfomationRow label="Type">
        <Typography variant="body1">
          {episode[EpisodeField.TYPE] === EpisodeType.NORMAL
            ? "Shortcast"
            : "Conversation"}
        </Typography>
      </InfomationRow>
      <InfomationRow label="Published at">
        <Typography variant="body1">
          {episode[EpisodeField.PUBLISHED_AT]
            ? format(
                (episode.publishedAt as unknown as Date | undefined) || 0,
                "dd/MM/yyyy"
              )
            : "Null"}
        </Typography>
      </InfomationRow>
      <InfomationRow label="Owner name">
        <ReferenceField
          label="Owner name"
          reference={Collection.USERS}
          source={EpisodeField.USER_ID}
        >
          <FunctionField
            render={(record: IUser) => (
              <Typography variant="body1">
                {
                  record[UserField.PERSONAL_INFO][
                    PersonalInfoMapField.FULL_NAME
                  ]
                }
              </Typography>
            )}
          />
        </ReferenceField>
      </InfomationRow>
      <InfomationRow label="Owner name">
        <ReferenceField
          label="Owner email"
          reference={Collection.USERS}
          source={EpisodeField.USER_ID}
        >
          <FunctionField
            render={(record: IUser) => (
              <Link variant="body1" href={`mailto:${record[UserField.EMAIL]}`}>
                {record[UserField.EMAIL]}
              </Link>
            )}
          />
        </ReferenceField>
      </InfomationRow>
      <InfomationRow label="Show on discover">
        <Stack flexDirection="row" justifyContent="center" gap={1}>
          {episode[EpisodeField.SHOW_ON_DISCOVER] ? (
            <TrueIcon color="success" />
          ) : (
            <FalseIcon color="error" />
          )}
          {episode[EpisodeField.SHOW_ON_DISCOVER] && (
            <Typography variant="body1" color="text.secondary">
              Priority: {episode[EpisodeField.DISCOVER_PRIORITY]}
            </Typography>
          )}
        </Stack>
      </InfomationRow>
    </Stack>
  );
};

const EpisodeDetail = () => (
  <Show title={<EpisodeDetailTitle />}>
    <SimpleShowLayout>
      <EpisodeInfomation />
      <Divider />
      <Detail />
    </SimpleShowLayout>
  </Show>
);

export default EpisodeDetail;
