import { Title } from "react-admin";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ListeningStatsContainer,
  ScrollableBox,
  StatsSection,
  ChartBox,
} from "./styles";
import Charts from "../components/Charts";
import CountriesStats from "./CountriesStats";
import {
  defineds,
  getDateFromToday,
  getDateStringFromMilliseconds,
} from "../utils";
import useStatisticData from "./useStatisticData";
import DateRangePicker from "../components/DateRangePicker";
import EpisodesStats from "./EpisodesStats";

const ListeningStats = () => {
  const {
    chartData,
    platforms,
    countries,
    os,
    from,
    loadingStatsData,
    episodeStats,
    loadingEpisodeStats,
    setFrom,
    to,
    setTo,
    isSelectMonth,
  } = useStatisticData();

  return (
    <ListeningStatsContainer>
      <Title title={`"Best Of" Statistic`} />
      <Stack alignItems="end">
        <Box>
          <DateRangePicker
            isSelectMonth={isSelectMonth}
            disabledDay={(date) => date < getDateFromToday(-60)}
            maxDate={defineds.endOfToday}
            from={from}
            to={to}
            scroll={{ enabled: true }}
            onRangeChange={(newFrom, newTo) => {
              setFrom(newFrom);
              setTo(newTo);
            }}
          />
        </Box>
      </Stack>
      <StatsSection>
        <Typography fontSize={24} fontWeight={700}>
          Plays by date
        </Typography>
        {loadingStatsData ? (
          <Stack justifyContent="center" alignItems="center" height="540px">
            <CircularProgress />
          </Stack>
        ) : (
          <ScrollableBox>
            <ChartBox>
              <Charts.BarChart
                yTitle="Plays"
                labels={chartData.map((el) =>
                  getDateStringFromMilliseconds(
                    el.time.toMillis(),
                    isSelectMonth ? "MMM yyyy" : "MMM dd, yyyy"
                  )
                )}
                data={chartData.map((el) => el.viewCount)}
              />
            </ChartBox>
          </ScrollableBox>
        )}
      </StatsSection>
      <StatsSection>
        <Typography fontSize={24} fontWeight={700}>
          Plays by country
        </Typography>
        <CountriesStats
          loading={loadingStatsData}
          data={countries.map((el) => ({
            name: el.name,
            countryCode: el.code,
            views: el.views,
            percent: el.percent,
          }))}
        />
      </StatsSection>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <StatsSection>
              <Typography fontSize={24} fontWeight={700}>
                Platforms
              </Typography>
              <Charts.DoughnutChart
                data={platforms.map((el) => ({
                  label: el.name,
                  value: el.views,
                  percent: el.percent,
                }))}
                loading={loadingStatsData}
              />
            </StatsSection>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StatsSection>
              <Typography fontSize={24} fontWeight={700}>
                OS
              </Typography>
              <Charts.DoughnutChart
                data={os.map((el) => ({
                  label: el.name,
                  value: el.views,
                  percent: el.percent,
                }))}
                loading={loadingStatsData}
              />
            </StatsSection>
          </Grid>
        </Grid>
      </Box>
      <StatsSection>
        <Typography fontSize={24} fontWeight={700} mb={2}>
          Your episodes
        </Typography>
        <EpisodesStats data={episodeStats} loading={loadingEpisodeStats} />
      </StatsSection>
    </ListeningStatsContainer>
  );
};

export default ListeningStats;
