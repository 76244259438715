import React, { useEffect, useState } from "react";
import {
  FieldProps,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import get from "lodash/get";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import Button from "@mui/material/Button";

interface ISelectableFieldOption {
  value: string | number;
  label?: string;
}

interface SelectableFieldProps {
  source: string;
  emptyText?: string;
  options: Array<ISelectableFieldOption>;
  menuProps?: Partial<MenuProps>;
  menuItemProps?: Partial<MenuItemProps>;
  adaptNewData?: (value: string | number) => object;
  isDisabled?: (record: any) => boolean;
}

const SelectableField = (props: SelectableFieldProps & FieldProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    source,
    options,
    emptyText = "None",
    menuProps,
    menuItemProps,
    adaptNewData,
    isDisabled,
  } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const [handleUpdate, { isLoading }] = useUpdate();
  const [selectedOption, setSelectedOption] =
    useState<ISelectableFieldOption>();
  const value = get(record, source, 0);
  const disabled = isLoading || (isDisabled && isDisabled(record));

  useEffect(() => {
    setSelectedOption(options.find((el) => el.value === value));
  }, [options, value]);

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        onClick={handleClick}
        endIcon={<DownArrowIcon />}
        sx={{ textTransform: "none" }}
      >
        {selectedOption?.label || selectedOption?.value || emptyText}
      </Button>
      <Menu
        onClick={(e) => e.stopPropagation()}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`select-option-${index}`}
            {...menuItemProps}
            onClick={() => {
              handleUpdate(resource, {
                id: record.id,
                data: adaptNewData
                  ? adaptNewData(option.value)
                  : {
                      [source]: option.value,
                    },
                previousData: record,
              });
              handleClose();
            }}
          >
            {option.label || option.value}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default SelectableField;
