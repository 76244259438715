import { useEffect } from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  NumberField,
  ListProps,
  WrapperField,
  EmailField,
  TextInput,
  SelectInput,
  FunctionField,
  useGetIdentity,
  useRefresh,
} from "react-admin";
import {
  ChannelField,
  IChannel,
  IUser,
  UserField,
  UserRole,
} from "../models/entities";
import { PersonalInfoMapField } from "../models/valueObjects";
import RssStatusIcon from "./components/RssStatusIcon";
import SelectableField from "../components/SelectableField";
import SyncedMailchimpField from "./components/SyncedMailchimpField";
import { useSearchParams } from "react-router-dom";
import EditableBooleanField from "../components/EditableBooleanField";
import { useAppContext } from "../contexts/AppContext";
import ChannelRelatedWrapper from "../components/ChannelRelatedWrapper";
import UserExporter from "./components/UserExporter";
import UserListActions from "./components/UserListActions";
import UserAvatarField from "./components/UserAvatarField";
import { capitalizeFirstLetter } from "../utils";
import { useTotalShowOnDefaultSearchList } from "./hooks/useTotalShowOnDefaultSearchList";

const postFilters = [
  <TextInput
    sx={{ marginBottom: "4px", minWidth: 400 }}
    label="Search"
    source={UserField.USERNAME}
    alwaysOn
  />,
  <SelectInput
    alwaysOn
    source={UserField.ROLE}
    choices={Object.values(UserRole).map((role, index) => ({
      key: index,
      id: role,
      name: role,
    }))}
  />,
];

const UserList = (props: ListProps) => {
  const refresh = useRefresh();
  const { data: userData, isLoading: isLoadingId } = useGetIdentity();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadChannelData } = useAppContext();
  const filterParams = searchParams.get("filter");
  const isFiltering = filterParams ? filterParams !== "{}" : false;
  const totalDefaultSearchUsers = useTotalShowOnDefaultSearchList();

  useEffect(() => {
    if (isFiltering) {
      searchParams.delete("sort");
      searchParams.delete("order");
      setSearchParams(searchParams);
    } else if (searchParams.get("sort") === "id") {
      searchParams.set("sort", UserField.CREATED_AT);
      searchParams.set("order", "DESC");
      setSearchParams(searchParams);
    }
  }, [isFiltering, searchParams, setSearchParams]);

  return (
    <List
      actions={<UserListActions />}
      filters={postFilters}
      exporter={UserExporter}
      className="custom-pagination-list"
      sort={{ field: "id", order: "asc" }}
      queryOptions={{
        refetchOnWindowFocus: false,
        onSuccess: ({ data }: { data: IUser[] }) =>
          loadChannelData(data.map((el) => el[UserField.ID])),
      }}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} optimized>
        <FunctionField render={() => <UserAvatarField />} label={false} />
        <TextField
          source={UserField.USERNAME}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <TextField
          sortable={!isFiltering}
          sortByOrder="DESC"
          label="Full name"
          source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.FULL_NAME}`}
        />
        <ChannelRelatedWrapper userIdKey={UserField.ID} label="Podcast title">
          <TextField source={ChannelField.TITLE} />
        </ChannelRelatedWrapper>
        <EmailField
          source={UserField.EMAIL}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <SelectableField
          isDisabled={(record: IUser) =>
            isLoadingId ||
            record[UserField.ID] === userData?.id ||
            !!record[UserField.IS_EXTERNAL_RSS_ACCOUNT]
          }
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={UserField.ROLE}
          options={Object.values(UserRole).map((role, index) => ({
            key: index,
            value: role,
            label: capitalizeFirstLetter(role),
          }))}
        />
        <NumberField
          source={UserField.EPISODE_COUNT}
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <NumberField
          source={UserField.FOLLOWER_COUNT}
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <NumberField
          source={UserField.FOLLOWING_COUNT}
          textAlign="center"
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <DateField
          source={UserField.CREATED_AT}
          sortable={!isFiltering}
          sortByOrder="DESC"
        />
        <ChannelRelatedWrapper
          userIdKey={UserField.ID}
          label="RSS status"
          textAlign="center"
        >
          <FunctionField
            source={ChannelField.IS_VERIFIED}
            render={(record: IChannel) => <RssStatusIcon channel={record} />}
          />
        </ChannelRelatedWrapper>
        <WrapperField label="Synced to MailChimp" textAlign="center">
          <SyncedMailchimpField />
        </WrapperField>
        <EditableBooleanField
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={UserField.SHOW_ON_DISCOVER}
        />
        <EditableBooleanField
          disabledTooltip="You can only show up to 20 users on the default search list"
          editFinished={() => refresh()}
          shouldDisabled={(record: IUser) =>
            totalDefaultSearchUsers >= 20 &&
            !record[UserField.SHOW_ON_DEFAULT_SEARCH_LIST]
          }
          sortable={!isFiltering}
          sortByOrder="DESC"
          textAlign="center"
          source={UserField.SHOW_ON_DEFAULT_SEARCH_LIST}
        />
      </Datagrid>
    </List>
  );
};

export default UserList;
