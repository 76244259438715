import * as React from "react";
import Box from "@mui/material/Box";
import { Title } from "react-admin";

const StatisticDashboard = () => (
  <Box height="100%">
    <Title title="Statistic dashboard" />
    <Box height="100%" display="flex" alignItems="center">
      <iframe
        title="statistic-embedded"
        width="100%"
        height="100%"
        src={process.env.REACT_APP_LOOKER_EMBED_URL}
        style={{ border: 0, overflow: "hidden" }}
        scrolling="no"
      />
    </Box>
  </Box>
);

export default StatisticDashboard;
