import { useEffect } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  ExportButton,
  SelectColumnsButton,
  TopToolbar,
  useListContext,
} from "react-admin";

interface DiscoverFilterProps {
  filteringDiscover: boolean;
  setFilteringDiscover: (value: boolean) => void;
}

const DiscoverFilter = ({
  filteringDiscover,
  setFilteringDiscover,
}: DiscoverFilterProps) => {
  const { setPage } = useListContext();

  useEffect(() => {
    if (filteringDiscover) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringDiscover]);

  return (
    <FormGroup>
      <FormControlLabel
        slotProps={{
          typography: {
            color: "primary",
            fontSize: 13,
          },
        }}
        control={
          <Checkbox
            size="small"
            icon={<UncheckedIcon color="primary" />}
            checked={filteringDiscover}
            onChange={(e) => setFilteringDiscover(e.target.checked)}
          />
        }
        label="SORT LIKE DISCOVER TAB"
      />
    </FormGroup>
  );
};

const EpisodeListActions = ({
  filteringDiscover,
  setFilteringDiscover,
}: DiscoverFilterProps) => (
  <TopToolbar sx={{ alignItems: "center" }}>
    <DiscoverFilter
      filteringDiscover={filteringDiscover}
      setFilteringDiscover={setFilteringDiscover}
    />
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

export default EpisodeListActions;
