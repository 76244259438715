import React from "react";
import get from "lodash/get";
import { FieldProps, useRecordContext } from "react-admin";
import { useAppContext } from "../../contexts/AppContext";

interface ChannelRelatedWrapperProps {
  children: JSX.Element;
  userIdKey: string;
}

const ChannelRelatedWrapper = ({
  children,
  userIdKey,
}: ChannelRelatedWrapperProps & FieldProps) => {
  const record = useRecordContext();
  const { channels } = useAppContext();
  const userId: string = get(record, userIdKey, "");
  const channel = get(channels, userId, {});

  if (!channel) {
    return <></>;
  }

  return React.cloneElement(children, { record: channel });
};

export default ChannelRelatedWrapper;
