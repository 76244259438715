import { useState } from "react";
import {
  FieldProps,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { ExternalRssSourceField, IExternalRssSource } from "../models/entities";
import { forceRenewExternalRss } from "../services/firebase/functions/forceRenewExternalRss";
import CircularProgress from "@mui/material/CircularProgress";

const ForceRefreshButton = (props: FieldProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const rssSource = useRecordContext<IExternalRssSource>(props);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await forceRenewExternalRss(rssSource[ExternalRssSourceField.ID]!);
      setTimeout(() => refresh(), 2000);
      notify("Refresh RSS feed successfully", { type: "success" });
    } catch (err) {
      notify((err as Error).message, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <IconButton
          disabled={loading}
          color="primary"
          onClick={handleButtonClick}
        >
          <RefreshIcon />
        </IconButton>
      )}

      {/*  */}
    </Box>
  );
};

export default ForceRefreshButton;
