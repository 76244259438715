import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipYAlignment,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { lighten, useTheme } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IBarChartProps {
  title?: string;
  xTitle?: string;
  yTitle?: string;
  data: number[];
  labels: any;
}

const BarChart = ({
  title = "",
  xTitle = "",
  yTitle = "",
  data,
  labels,
}: IBarChartProps) => {
  const { palette } = useTheme();
  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        border: { display: false },
        title: {
          display: true,
          text: xTitle,
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: yTitle,
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      tooltip: {
        yAlign: "bottom" as TooltipYAlignment,
        borderWidth: 0.15,
        borderColor: "black",
        displayColors: false,
        backgroundColor: "white",
        titleColor: palette.text.primary,
        bodyColor: palette.text.secondary,
        titleFont: { size: 13 },
        caretPadding: 4,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: "black",
        font: {
          size: 24,
          weight: "bold",
        },
      },
    },
  };

  return (
    <Bar
      options={options}
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: lighten("#450766", 0.25),
            hoverBackgroundColor: "#450766",
            maxBarThickness: 60,
          },
        ],
      }}
    />
  );
};

export default BarChart;
