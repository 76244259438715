export enum CategoryMapField {
  TEXT = 'text',
  ICON = 'icon',
  SUBCATEGORIES = 'subCategories',
}

export interface ICategoryMap {
  [CategoryMapField.TEXT]: string;
  [CategoryMapField.ICON]?: string;
  [CategoryMapField.SUBCATEGORIES]?: string[];
}
