import { useNotify, useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EpisodeField,
  IEpisode,
  IUser,
  UserField,
} from "../../models/entities";
import { getUserEpisodes } from "../../services/firebase/functions/getUserEpisodes";
import { PersonalInfoMapField } from "../../models/valueObjects";
import assetImages from "../../assets/images";

const UserEpisodeDetail = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const record = useRecordContext<IUser>();
  const [isLoading, setIsLoading] = useState(false);
  const [episodes, setEpisodes] = useState<IEpisode[]>([]);
  const [isFinished, setIsFinished] = useState(false);

  const getMoreData = useCallback(
    async (isRefresh: boolean) => {
      setIsLoading(true);
      try {
        const { hasNext, data }: { hasNext: boolean; data: IEpisode[] } =
          await getUserEpisodes(
            record.id,
            10,
            isRefresh
              ? undefined
              : get(episodes, `${episodes.length - 1}.${EpisodeField.ID}`)
          );
        setIsFinished(!hasNext);
        setEpisodes(isRefresh ? data : [...episodes, ...data]);
      } catch (err) {
        notify((err as Error).message, { type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [episodes, notify, record]
  );

  useEffect(() => {
    if (record.id && episodes.length === 0) {
      getMoreData(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack justifyContent="center" py={4} px={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fff1f1" }}>
              <TableCell width={30} />
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Published At</TableCell>
              <TableCell align="center">Visibility</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {episodes.length === 0 && !isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  No data
                </TableCell>
              </TableRow>
            ) : (
              episodes.map((episode) => (
                <TableRow
                  hover
                  onClick={() =>
                    navigate(`/episodes/${episode[EpisodeField.ID]}/show`)
                  }
                  key={episode[EpisodeField.ID]}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell align="center">
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      alt="avatar"
                      src={
                        record[UserField.PERSONAL_INFO][
                          PersonalInfoMapField.AVATAR
                        ] || assetImages.defaultAvatar
                      }
                    />
                  </TableCell>
                  <TableCell>{episode[EpisodeField.TITLE]}</TableCell>
                  <TableCell>{episode[EpisodeField.DESCRIPTION]}</TableCell>
                  <TableCell align="center">
                    {format(
                      (episode[EpisodeField.PUBLISHED_AT]?.seconds || 0) * 1000,
                      "dd/MM/yyyy"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {episode[EpisodeField.VISIBILITY]}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress size={32} />
        </Box>
      )}
      {!isFinished && !isLoading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => getMoreData(false)}
          >
            Load more
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default UserEpisodeDetail;
