import {
  useRecordContext,
  FieldProps,
  ReferenceField,
  FunctionField,
} from "react-admin";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import get from "lodash/get";
import format from "date-fns/format";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import PlayIcon from "@mui/icons-material/PlayCircleRounded";
import PauseIcon from "@mui/icons-material/PauseCircleRounded";
import BackIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { EpisodeField, IEpisode, IUser, UserField } from "../models/entities";
import { PersonalInfoMapField } from "../models/valueObjects";
import AvatarField from "../components/AvatarField";
import { Collection } from "../models/constants";
import { usePlayerContext } from "../contexts/PlayerContext";

const EpisodeInfomation = (props: FieldProps) => {
  const navigate = useNavigate();
  const episode = useRecordContext<IEpisode>();
  const { playEpisodes, currentEpisodeIndex, playlist, isPlaying, togglePlay } =
    usePlayerContext();
  const playingEpisode = get(playlist, currentEpisodeIndex, undefined);
  const isPlayingThisEpisode = episode.id === playingEpisode?.id && isPlaying;

  if (!episode) return null;
  return (
    <Stack paddingX={6} paddingY={4} gap={3}>
      <Box display="flex" flexDirection="row" gap={4}>
        <ReferenceField
          reference={Collection.USERS}
          source={EpisodeField.USER_ID}
        >
          <AvatarField
            avatarProps={{ variant: "rounded" }}
            size={140}
            source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.AVATAR}`}
          />
        </ReferenceField>
        <Stack flex={1}>
          <Typography fontSize={28} fontWeight={600}>
            {episode[EpisodeField.TITLE]}
          </Typography>
          <Stack flexDirection="row" gap={1}>
            <Typography color="text.disabled">
              {`${format(
                (episode.createdAt as Date | undefined) || 0,
                "dd/MM/yyyy"
              )} -`}
            </Typography>
            <ReferenceField
              reference={Collection.USERS}
              source={EpisodeField.USER_ID}
            >
              <FunctionField
                render={(user: IUser) => (
                  <Typography color="text.disabled">
                    by{" "}
                    <Link
                      to={`/users/${user[UserField.ID]}/show`}
                      component={LinkRouter}
                    >
                      @{user.username}
                    </Link>{" "}
                  </Typography>
                )}
              />
            </ReferenceField>
          </Stack>
          <Stack flexDirection="row" gap={2} mt={1}>
            <IconButton
              onClick={() => {
                if (episode.id !== playingEpisode?.id) {
                  playEpisodes([episode]);
                } else {
                  togglePlay();
                }
              }}
              sx={{ fontSize: 56, color: "#73DA23" }}
              edge="start"
            >
              {isPlayingThisEpisode ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayIcon fontSize="inherit" />
              )}
            </IconButton>
            <Box display="flex" alignItems="center">
              <Stack
                minWidth={80}
                justifyContent="center"
                borderRadius={2}
                padding={1.5}
                sx={{ backgroundColor: "#FBF4FF" }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color="text.secondary"
                  lineHeight={1.2}
                >
                  {episode[EpisodeField.VIEWS_COUNT] || 0}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  color="text.disabled"
                  lineHeight={1.2}
                >
                  Plays
                </Typography>
              </Stack>
            </Box>
            <Box display="flex" alignItems="center">
              <Stack
                minWidth={80}
                justifyContent="center"
                borderRadius={2}
                padding={1.5}
                sx={{ backgroundColor: "#FBF4FF" }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color="text.secondary"
                  lineHeight={1.2}
                >
                  {episode[EpisodeField.INTERNAL_VIEWS_COUNT] || 0}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  color="text.disabled"
                  lineHeight={1.2}
                >
                  Internal Plays
                </Typography>
              </Stack>
            </Box>
            <Box display="flex" alignItems="center">
              <Stack
                minWidth={80}
                justifyContent="center"
                borderRadius={2}
                padding={1.5}
                sx={{ backgroundColor: "#FBF4FF" }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color="text.secondary"
                  lineHeight={1.2}
                >
                  {(episode[EpisodeField.VIEWS_COUNT] || 0) -
                    (episode[EpisodeField.INTERNAL_VIEWS_COUNT] || 0)}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  color="text.disabled"
                  lineHeight={1.2}
                >
                  External Plays
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Box alignSelf="start">
          <Button
            startIcon={<BackIcon />}
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </Box>
      </Box>
      <Typography fontSize={16} color="text.secondary">
        {episode[EpisodeField.DESCRIPTION] || "Null"}
      </Typography>
    </Stack>
  );
};
export default EpisodeInfomation;
