import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { Collection } from "../../models/constants";
import {
  BestOfEpisodeField,
  EpisodeProcessStatus,
  IBestOfEpisode,
} from "../../models/entities";
import { firestore } from "./clientApp";

export const bestOfEpisodeCollection = collection(
  firestore,
  Collection.BEST_OF_EPISODES
);

export const getDraftBestOfEpisode = async () => {
  const q = query(
    bestOfEpisodeCollection,
    where(
      BestOfEpisodeField.PROCESS_STATUS,
      "!=",
      EpisodeProcessStatus.COMPLETED
    ),
    limit(1)
  );
  const res = await getDocs(q);
  if (res.docs.length > 0) {
    return res.docs[0].data() as IBestOfEpisode;
  }
  return undefined;
};
