import { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import BackIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import NotificationIcon from "@mui/icons-material/NotificationsNone";
import SendNotificationDialog from "../../components/SendNotificationDialog";
import { adminSendNotification } from "../../services/firebase/functions/adminSendNotification";
import { IUser, UserField } from "../../models/entities";

const UserInfomationActions = () => {
  const user = useRecordContext<IUser>();
  const notify = useNotify();
  const navigate = useNavigate();
  const [sendingNotification, setSendingNotification] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const onSend = async (title: string, body: string) => {
    try {
      setSendingNotification(true);
      await adminSendNotification(title, body, user[UserField.ID]);
      setSendingNotification(false);
      notify("Send notification to user successfully", { type: "success" });
    } catch (err) {
      notify((err as Error).message, { type: "error" });
    }
    setOpenNotification(false);
  };

  return (
    <>
      <Stack flexDirection="row" alignSelf="flex-start" gap={2}>
        {!user[UserField.IS_EXTERNAL_RSS_ACCOUNT] && (
          <Button
            startIcon={<NotificationIcon />}
            variant="outlined"
            onClick={() => setOpenNotification(true)}
          >
            Send notification
          </Button>
        )}
        <Button
          startIcon={<BackIcon />}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Go back
        </Button>
      </Stack>
      <SendNotificationDialog
        loading={sendingNotification}
        open={openNotification}
        onSend={onSend}
        onClose={() => setOpenNotification(false)}
      />
    </>
  );
};

export default UserInfomationActions;
