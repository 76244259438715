import {
  List,
  ListProps,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
  ArrayField,
  SingleFieldList,
  TopToolbar,
  TextInput,
} from "react-admin";
import { Link as LinkRouter } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  ChannelField,
  ExternalRssSourceField,
  IExternalRssSource,
  IUser,
  UserField,
} from "../models/entities";
import { Collection } from "../models/constants";
import AvatarField from "../components/AvatarField";
import {
  CategoryMapField,
  ICategoryMap,
  PersonalInfoMapField,
} from "../models/valueObjects";
import DeleteExternalRssButton from "./DeleteExternalRssButton";
import ImportRssButton from "./components/ImportRssButton";
import ForceRefreshButton from "./ForceRefreshButton";
import { useTypographyStyle } from "../globals/styles";
import Typography from "@mui/material/Typography";
import InboxIcon from "@mui/icons-material/Inbox";

const ListActions = () => (
  <TopToolbar sx={{ alignItems: "center" }}>
    <ImportRssButton />
  </TopToolbar>
);

const listFilters = [
  <TextInput
    sx={{ marginBottom: "4px", minWidth: 400 }}
    label="Search"
    source={ExternalRssSourceField.RSS_URL}
    alwaysOn
  />,
];

const Empty = () => (
  <Stack alignItems="center" width="100%" fontSize={216} gap={1} color="grey">
    <InboxIcon fontSize="inherit" />
    <Typography variant="h4" paragraph mb={4}>
      No External RSS yet.
    </Typography>
    <ImportRssButton variant="contained" />
  </Stack>
);

const ExternalRssList = (props: ListProps) => {
  const classes = useTypographyStyle();
  return (
    <List
      {...props}
      empty={<Empty />}
      filters={listFilters}
      actions={<ListActions />}
      className="custom-pagination-list"
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="Episode owner"
          sortByOrder="DESC"
          source={ExternalRssSourceField.USER_ID}
          reference={Collection.USERS}
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <AvatarField
              size={32}
              source={`${UserField.PERSONAL_INFO}.${PersonalInfoMapField.AVATAR}`}
            />
            <FunctionField
              render={(user: IUser) => (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/users/${user[UserField.ID]}/show`}
                  component={LinkRouter}
                >
                  {user[UserField.USERNAME]}
                </Link>
              )}
            />
          </Stack>
        </ReferenceField>
        <FunctionField
          label="RSS URL"
          source={ExternalRssSourceField.RSS_URL}
          sortByOrder="DESC"
          render={(user: IExternalRssSource) => (
            <Box width={150}>
              <Link
                className={classes.limitLineTypography}
                sx={{ wordBreak: "break-all" }}
                href={user[ExternalRssSourceField.RSS_URL]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user[ExternalRssSourceField.RSS_URL]}
              </Link>
            </Box>
          )}
        />
        <ReferenceField
          sortByOrder="DESC"
          label="Title"
          source={ExternalRssSourceField.CHANNEL_ID}
          reference={Collection.CHANNELS}
        >
          <TextField source={ChannelField.TITLE} />
        </ReferenceField>
        <ReferenceField
          sortByOrder="DESC"
          label="Description"
          source={ExternalRssSourceField.CHANNEL_ID}
          reference={Collection.CHANNELS}
        >
          <TextField
            className={classes.limitLineTypography}
            source={ChannelField.DESCRIPTION}
          />
        </ReferenceField>
        <ReferenceField
          sortByOrder="DESC"
          label="Categories"
          source={ExternalRssSourceField.CHANNEL_ID}
          reference={Collection.CHANNELS}
        >
          <ArrayField source={ChannelField.CATEGORIES}>
            <SingleFieldList linkType={false} sx={{ gap: "4px" }}>
              <FunctionField
                render={(data: ICategoryMap) => (
                  <Chip
                    label={
                      data[CategoryMapField.SUBCATEGORIES]?.length
                        ? `${data[CategoryMapField.TEXT]} - ${
                            data[CategoryMapField.SUBCATEGORIES][0]
                          }`
                        : data[CategoryMapField.TEXT]
                    }
                  />
                )}
              />
            </SingleFieldList>
          </ArrayField>
        </ReferenceField>
        <ReferenceField
          textAlign="center"
          sortByOrder="DESC"
          label="Episode count"
          source={ExternalRssSourceField.USER_ID}
          reference={Collection.USERS}
        >
          <TextField source={UserField.EPISODE_COUNT} />
        </ReferenceField>
        <DateField
          showTime
          source={ExternalRssSourceField.LAST_REFRESHED_AT}
          sortByOrder="DESC"
        />
        <DateField
          showTime
          source={ExternalRssSourceField.CREATED_AT}
          sortByOrder="DESC"
        />
        <ForceRefreshButton label="Refresh" textAlign="center" />
        <DeleteExternalRssButton label="Remove" textAlign="center" />
      </Datagrid>
    </List>
  );
};

export default ExternalRssList;
