import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthProvider } from "react-admin";
import { auth } from "../services/firebase/clientApp";
import { getUserById } from "../services/firebase/user";
import { UserField, UserRole } from "../models/entities";
import { getPublicUserInfoById } from "../services/firebase/publicUserInfo";
import { getUserIdLoggedIn } from "./helpers";
import { Collection } from "../models/constants";

const allowedRoles = [UserRole.ADMIN, UserRole.ANALYST];

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    if (username && password) {
      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          username,
          password
        );
      } catch (e) {
        throw new Error("Login error: invalid credentials");
      }
      const user = await getUserById(userCredential.user.uid);
      if (
        !user?.[UserField.ROLE] ||
        !allowedRoles.includes(user[UserField.ROLE])
      ) {
        throw new Error("Login error: required an admin role account");
      }
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject({ message: false });
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const userId = await getUserIdLoggedIn();
    const user = userId ? await getUserById(userId) : undefined;

    if (
      !user?.[UserField.ROLE] ||
      !allowedRoles.includes(user[UserField.ROLE])
    ) {
      throw new Error(
        "Unauthorized: required an admin or analyst role account"
      );
    }
  },
  logout: () => auth.signOut(),
  getIdentity: async () => {
    try {
      const userId = auth.currentUser?.uid;
      const user = userId ? await getPublicUserInfoById(userId) : undefined;
      if (!user) {
        throw new Error("Unauthenticated");
      }
      return {
        id: user.id,
        fullName: user.personalInfo.fullName,
        avatar: user.personalInfo.avatar,
      };
    } catch (error) {
      throw error;
    }
  },
  getPermissions: async () => {
    const userId = await getUserIdLoggedIn();
    const user = userId ? await getUserById(userId) : undefined;
    if (user && user[UserField.ROLE] === UserRole.ADMIN) {
      return [
        Collection.USERS,
        Collection.EPISODES,
        Collection.EXTERNAL_RSS_SOURCES,
      ];
    }
    return [];
  },
};

export default authProvider;
