import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

export const PlayerContainer = styled(Stack)`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 12px 32px;
  background-color: #efefef;
`;

export const PlayingEpisodeInfomation = styled(Stack)`
  flex: 1;
`;

export const PlayerActionsContainer = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const EpisodeTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const EpisodeDescription = styled.span`
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const EpisodeDuration = styled.span`
  font-size: 13px;
`;

export const PlayerActionButton = styled(IconButton)<{ fontSize?: number }>`
  font-size: ${({ fontSize = 32 }) => fontSize}px;
`;
