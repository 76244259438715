import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { LoadingStack } from "../../globals/styles";
import { StyledStepLabel } from "./style";
import ImportRssStep1 from "./ImportRssStep1";
import ImportRssStep2 from "./ImportRssStep2";
import ImportRssStep3 from "./ImportRssStep3";
import { IExternalRssFeedMap } from "../../models/valueObjects";

interface ImportRssDialogProps {
  open: boolean;
  handleClose: () => void;
  handleOk: (url: string, username: string) => Promise<boolean>;
}

const steps = [
  "Enter podcast RSS feed",
  "Confirm your selection",
  "Send request",
];

const ImportRssDialog = ({
  open,
  handleClose,
  handleOk,
}: ImportRssDialogProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [importedFeed, setImportedFeed] = useState<IExternalRssFeedMap>();
  const [importedUrl, setImportedUrl] = useState<string>();

  useEffect(() => {
    if (!open) {
      setCurrentStep(1);
      setLoading(false);
      setImportedFeed(undefined);
      setImportedUrl(undefined);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => !loading && handleClose()}
    >
      <DialogTitle marginTop={2}>
        <Stepper activeStep={currentStep - 1}>
          {steps.map((label) => (
            <Step key={label}>
              <StyledStepLabel>{label}</StyledStepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      {currentStep === 1 && (
        <ImportRssStep1
          handleClose={handleClose}
          moveToNextStep={(url, feed) => {
            setImportedUrl(url);
            setImportedFeed(feed);
            setCurrentStep(2);
          }}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {currentStep === 2 && (
        <ImportRssStep2
          handleGoBack={() => {
            setImportedUrl(undefined);
            setImportedFeed(undefined);
            setCurrentStep(1);
          }}
          moveToNextStep={() => {
            setCurrentStep(3);
          }}
          loading={loading}
          setLoading={setLoading}
          feed={importedFeed}
        />
      )}
      {currentStep === 3 && (
        <ImportRssStep3
          handleGoBack={() => setCurrentStep(2)}
          handleConfirm={async (username) => {
            if (importedUrl) {
              const isSuccess = await handleOk(importedUrl, username);
              setLoading(false);
              isSuccess && handleClose();
            }
          }}
          loading={loading}
          setLoading={setLoading}
          feed={importedFeed}
        />
      )}
      {loading && (
        <LoadingStack>
          <CircularProgress />
        </LoadingStack>
      )}
    </Dialog>
  );
};

export default ImportRssDialog;
