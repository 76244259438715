import { useCallback } from "react";
import styled from "styled-components";
import { scaleLinear } from "d3-scale";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import StatsTable, { StatsTableTypes } from "../components/StatsTable";

export const MapComponent = styled(ComposableMap)`
  width: 100%;
`;

const columns: StatsTableTypes.ITableColumn[] = [
  {
    text: "Country",
    key: "name",
    subTitle: "The country from which user listened to your shortcast",
    bodyCellProps: { sx: { fontWeight: 700 } },
  },
  {
    text: "Views",
    key: "views",
    subTitle: "The total number of views",
    headerProps: { align: "right" },
    bodyCellProps: { align: "right", sx: { width: 140 } },
  },
];

interface ICountryData extends StatsTableTypes.ITableRowData {
  name: string;
  countryCode: string;
  views: number;
  percent: number;
}

interface CountriesStatsProps {
  data: ICountryData[];
  loading: boolean;
}

const CountriesStats = ({ data, loading }: CountriesStatsProps) => {
  const theme = useTheme();

  const getOpacities = useCallback(
    (value: number) => {
      if (!value || !data.length) return 0.1;
      return scaleLinear()
        .domain([0, Math.max(...data.map((el) => el.views as number))])
        .range([0.5, 1])(value);
    },
    [data]
  );

  if (loading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        height={{ xs: 260, md: 400, lg: 500 }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item display={{ xs: "none", sm: "block" }} sm={6} md={7} xl={8}>
          <Box display="flex" alignItems="center" height="100%">
            <MapComponent
              projection="geoMercator"
              projectionConfig={{ center: [10, 40], scale: 120 }}
            >
              <Geographies geography="/mapGeo.json">
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const country = data.find(
                      (el) =>
                        el.countryCode.toString().toUpperCase() ===
                        geo.properties["Alpha-2"]
                    );
                    const viewsCount = country?.views || 0;
                    const percent = country?.percent || 0;
                    return (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#fff",
                              border: "0.15px solid #7d7d7d",
                              shadow: "none",
                            },
                          },
                        }}
                        followCursor
                        title={
                          <Stack gap="6px">
                            <span
                              style={{
                                fontSize: 13,
                                color: theme.palette.text.primary,
                              }}
                            >
                              {geo.properties.name}
                            </span>
                            <span
                              style={{ color: theme.palette.text.secondary }}
                            >
                              {viewsCount} ({percent}%)
                            </span>
                          </Stack>
                        }
                        key={geo.rsmKey}
                      >
                        <Geography
                          style={{
                            default: { outline: "none" },
                            hover: { outline: "none", fillOpacity: 1 },
                            pressed: { outline: "none" },
                          }}
                          geography={geo}
                          stroke="#FFFFFF"
                          strokeWidth={0.75}
                          fillOpacity={getOpacities(viewsCount as number)}
                          fill={theme.palette.primary.main}
                        />
                      </Tooltip>
                    );
                  })
                }
              </Geographies>
            </MapComponent>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} xl={4}>
          <StatsTable columns={columns} rows={data} valueKey="views" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CountriesStats;
