import {
  Show,
  ShowProps,
  useRecordContext,
  TabbedShowLayout,
} from "react-admin";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { IUser, UserField } from "../models/entities";
import UserInfomation from "./components/UserInfomation";
import ExpandPanel from "./components/UserEpisodeDetail";
import UserChannelDetail from "./components/UserChannelDetail";

const UserDetailTitle = () => {
  const user = useRecordContext<IUser>();

  if (!user) return null;
  return <span>User "{user[UserField.USERNAME]}"</span>;
};

const UserDetail = (props: ShowProps) => {
  return (
    <Show title={<UserDetailTitle />} component={Box}>
      <Paper elevation={3} sx={{ marginBottom: 2 }}>
        <UserInfomation />
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="channel">
            <UserChannelDetail />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="episodes" path="episodes">
            <ExpandPanel />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Paper>
    </Show>
  );
};

export default UserDetail;
