import {
  ExternalRssItemMapField,
  IExternalRssItemMap,
} from "../../models/valueObjects";
import { PreviewItemContainer } from "./style";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { getDateStringFromMilliseconds } from "../../utils";
import Stack from "@mui/system/Stack";

interface ImportRssPreviewListProps {
  items: IExternalRssItemMap[];
}

const ImportRssPreviewList = ({ items }: ImportRssPreviewListProps) => {
  return (
    <PreviewItemContainer paddingTop={4}>
      <Box paddingX={4} paddingBottom={2}>
        <Typography variant="h5" fontWeight={600} marginBottom={3}>
          Episodes
        </Typography>
        <Stack
          color="#007b83"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography color="#inherit" fontWeight={600} variant="subtitle1">
            EPISODE NAME
          </Typography>
          <Typography color="inherit" fontWeight={600} variant="subtitle1">
            RELEASE DATE
          </Typography>
        </Stack>
        {items.length > 0 ? (
          items.map((item) => (
            <Stack
              marginY={3}
              key={item[ExternalRssItemMapField.GUID]}
              color="#3c4043"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                component="a"
                href={item[ExternalRssItemMapField.URL]}
                rel="noopener noreferrer"
                target="_blank"
                color="inherit"
                variant="body1"
                fontWeight={600}
                sx={{ textDecoration: "none" }}
              >
                {item[ExternalRssItemMapField.TITLE]}
              </Typography>
              <Typography color="inherit" variant="body1">
                {getDateStringFromMilliseconds(
                  item[ExternalRssItemMapField.PUBLISHED_AT]?.toMillis() || 0,
                  "MMM dd,yyyy"
                )}
              </Typography>
            </Stack>
          ))
        ) : (
          <Stack
            marginY={3}
            color="#3c4043"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              color="inherit"
              variant="body1"
              fontWeight={600}
              sx={{ textDecoration: "none" }}
            >
              -
            </Typography>
            <Typography color="inherit" variant="body1">
              -
            </Typography>
          </Stack>
        )}
      </Box>
      <Divider />
      <Box textAlign="center" paddingY={1.5}>
        <Typography color="#5f6368">
          {items.length} {items.length > 1 ? "episodes" : "episode"} in the feed
        </Typography>
      </Box>
    </PreviewItemContainer>
  );
};

export default ImportRssPreviewList;
