import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../services/firebase/clientApp";
import { Collection } from "../../models/constants";
import { UserField } from "../../models/entities";

export const useTotalShowOnDefaultSearchList = (): number => {
  const [totalDefaultUsers, setTotalDefaultUsers] = useState(0);

  useEffect(() => {
    const collectionRef = collection(firestore, Collection.USERS);
    const q = query(
      collectionRef,
      where(UserField.SHOW_ON_DEFAULT_SEARCH_LIST, "==", true)
    );
    const unsubcribe = onSnapshot(q, (snapshots) => {
      setTotalDefaultUsers(snapshots.docs.length);
    });
    return () => {
      unsubcribe();
    };
  }, []);

  return totalDefaultUsers;
};
