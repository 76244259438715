import { collection, getDocs, query, where } from "firebase/firestore";
import { Collection } from "../../models/constants";
import { ChannelField, IChannel } from "../../models/entities";
import { firestore } from "./clientApp";
import { chunk, flatten } from "lodash";

export const channelCollection = collection(firestore, Collection.CHANNELS);

export const getChannelByUserId = async (
  userId: string
): Promise<IChannel | undefined> => {
  const q = query(channelCollection, where(ChannelField.USER_ID, "==", userId));
  const res = await getDocs(q);
  if (res.docs.length > 0) {
    return res.docs[0].data() as IChannel;
  }
  return undefined;
};

export const getChannelByUserIds = async (
  userIds: string[]
): Promise<IChannel[]> => {
  const chunks = chunk(userIds, 30);
  const chunksRes = await Promise.all(
    chunks.map(async (chunk) => {
      const q = query(
        channelCollection,
        where(ChannelField.USER_ID, "in", chunk)
      );
      const res = await getDocs(q);
      return res.docs.map((doc) => doc.data() as IChannel);
    })
  );
  return flatten(chunksRes);
};
