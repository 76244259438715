import React, { useEffect, useState } from "react";
import get from "lodash/get";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SkipNextIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousIcon from "@mui/icons-material/SkipPreviousRounded";
import PlayCircleIcon from "@mui/icons-material/PlayCircleRounded";
import PauseCircleIcon from "@mui/icons-material/PauseCircleRounded";
import RepeatIcon from "@mui/icons-material/RepeatRounded";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {
  EpisodeDescription,
  EpisodeDuration,
  EpisodeTitle,
  PlayerActionButton,
  PlayerActionsContainer,
  PlayerContainer,
  PlayingEpisodeInfomation,
} from "./styles";
import { usePlayerContext } from "../../contexts/PlayerContext";
import {
  EpisodeField,
  IPublicUserInfo,
  UserField,
} from "../../models/entities";
import Slider from "@mui/material/Slider";
import { getDurationString } from "../../utils";
import { AudioMapField, PersonalInfoMapField } from "../../models/valueObjects";
import { Collection } from "../../models/constants";
import assetImages from "../../assets/images";
import { dataProvider } from "../../providers";

const Player = () => {
  const {
    currentEpisodeIndex,
    playlist,
    isPlaying,
    togglePlay,
    playPrevious,
    isLooping,
    toggleLoop,
    clearPlayerState,
    progress,
    audioRef,
    onEpisodeEnded,
    setupPlayer,
  } = usePlayerContext();
  const [isSeeking, setIsSeeking] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [avatar, setAvatar] = useState(assetImages.defaultAvatar);
  const playingEpisode = get(playlist, currentEpisodeIndex, undefined);
  const hasNext = currentEpisodeIndex + 1 < playlist.length;
  const audioUrl = playingEpisode?.[EpisodeField.AUDIO]?.[AudioMapField.URL];
  const audioDuration =
    playingEpisode?.[EpisodeField.AUDIO]?.[AudioMapField.DURATION] || 0;

  useEffect(() => {
    const getUser = async (id: string) => {
      const { data: user }: { data: IPublicUserInfo } =
        await dataProvider.getOne(Collection.PUBLIC_USER_INFOS, { id });
      setAvatar(
        user[UserField.PERSONAL_INFO][PersonalInfoMapField.AVATAR] ||
          assetImages.defaultAvatar
      );
    };

    const userId = playingEpisode?.[EpisodeField.USER_ID];
    userId && getUser(userId);
  }, [playingEpisode]);

  useEffect(() => {
    if (!isSeeking) {
      setCurrentTime(progress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleSeek = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value / 1000;
      setCurrentTime(value);
    }
  };

  if (!playingEpisode) {
    return null;
  }

  return (
    <>
      <PlayerContainer flexDirection="row" gap={4}>
        <Avatar sx={{ width: 56, height: 56 }} alt="avatar" src={avatar} />
        <PlayingEpisodeInfomation>
          <EpisodeTitle>{playingEpisode[EpisodeField.TITLE]}</EpisodeTitle>
          <EpisodeDescription>
            {playingEpisode[EpisodeField.DESCRIPTION] || "-"}
          </EpisodeDescription>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <EpisodeDuration>{getDurationString(progress)}</EpisodeDuration>
            <Slider
              size="small"
              value={currentTime}
              max={audioDuration}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => getDurationString(value)}
              onChange={(_, value) => {
                setIsSeeking(true);
                setCurrentTime(value as number);
              }}
              onChangeCommitted={(_, value) => {
                setIsSeeking(false);
                handleSeek(value as number);
              }}
            />
            <EpisodeDuration>
              {getDurationString(audioDuration)}
            </EpisodeDuration>
          </Stack>
        </PlayingEpisodeInfomation>
        <PlayerActionsContainer ml={{ md: 16, lg: 32, xl: 48 }}>
          <Box>
            <PlayerActionButton
              onClick={() => playPrevious()}
              aria-label="previous"
            >
              <SkipPreviousIcon fontSize="inherit" />
            </PlayerActionButton>
          </Box>
          <Box>
            <PlayerActionButton
              onClick={() => {
                togglePlay();
              }}
              aria-label="play"
            >
              {isPlaying ? (
                <PauseCircleIcon fontSize="inherit" />
              ) : (
                <PlayCircleIcon fontSize="inherit" />
              )}
            </PlayerActionButton>
          </Box>
          <Box>
            <PlayerActionButton disabled={!hasNext} aria-label="next">
              <SkipNextIcon fontSize="inherit" />
            </PlayerActionButton>
          </Box>
          <Box paddingX={1.5} color="#260238">
            <PlayerActionButton
              onClick={() => {
                toggleLoop();
              }}
              fontSize={26}
              aria-label="repeat"
              color={isLooping ? "inherit" : "default"}
            >
              <RepeatIcon fontSize="inherit" />
            </PlayerActionButton>
          </Box>
          <Box>
            <PlayerActionButton
              aria-label="clear-player"
              onClick={() => clearPlayerState()}
            >
              <CloseIcon fontSize="inherit" />
            </PlayerActionButton>
          </Box>
        </PlayerActionsContainer>
      </PlayerContainer>
      {audioUrl && (
        <audio
          src={audioUrl}
          ref={audioRef}
          onEnded={onEpisodeEnded}
          onError={onEpisodeEnded}
          onLoadedMetadata={setupPlayer}
        />
      )}
    </>
  );
};

export default Player;
