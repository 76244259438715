import { httpsCallable } from "firebase/functions";
import { functions } from "../clientApp";
import { mapValuesDeep } from "../utils/mapValuesDeep";

export const parseExternalRssFeed = async (url: string) => {
  try {
    const { data }: { data: any } = await httpsCallable(
      functions,
      "parseExternalRssFeed"
    )({ url });
    if (data.success) {
      return mapValuesDeep(data.result);
    } else {
      throw new Error("Failed to remove external RSS");
    }
  } catch {
    throw new Error("Failed to remove external RSS");
  }
};
