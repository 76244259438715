export enum PersonalInfoMapField {
  AVATAR = 'avatar',
  BIO = 'bio',
  FULL_NAME = 'fullName',
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
}

export interface IPersonalInfoMap {
  [PersonalInfoMapField.AVATAR]?: string;
  [PersonalInfoMapField.BIO]?: string;
  [PersonalInfoMapField.FULL_NAME]?: string;
  [PersonalInfoMapField.FIRST_NAME]?: string;
  [PersonalInfoMapField.LAST_NAME]?: string;
}

/**
 * Personal info map class
 */
export class PersonalInfoMap {
  /**
   *  @return {IPersonalInfoMap} Default IPersonalInfoMap value
   */
  static default = (): IPersonalInfoMap => {
    return {
      [PersonalInfoMapField.AVATAR]: '',
      [PersonalInfoMapField.BIO]: '',
      [PersonalInfoMapField.FULL_NAME]: '',
      [PersonalInfoMapField.FIRST_NAME]: '',
      [PersonalInfoMapField.LAST_NAME]: '',
    };
  };
}
