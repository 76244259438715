import { Timestamp } from "firebase/firestore";

export enum ExternalRssSourceProcessStatus {
  IDLE = "IDLE",
  PRE_PROGRESS = "PRE_PROGRESS",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ExternalRssSourceField {
  ID = "id",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  LAST_REFRESHED_AT = "lastRefreshedAt",
  RSS_URL = "rssUrl",
  USER_ID = "userId",
  CHANNEL_ID = "channelId",
  IMPORTED_BY = "importedBy",
  PROCESS_STATUS = "processStatus",
}

export interface IExternalRssSource {
  [ExternalRssSourceField.ID]?: string;
  [ExternalRssSourceField.CREATED_AT]?: Timestamp;
  [ExternalRssSourceField.UPDATED_AT]?: Timestamp;
  [ExternalRssSourceField.LAST_REFRESHED_AT]?: Timestamp;
  [ExternalRssSourceField.RSS_URL]?: string;
  [ExternalRssSourceField.USER_ID]?: string;
  [ExternalRssSourceField.CHANNEL_ID]?: string;
  [ExternalRssSourceField.IMPORTED_BY]?: string;
  [ExternalRssSourceField.PROCESS_STATUS]?: ExternalRssSourceProcessStatus;
}
