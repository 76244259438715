import { Timestamp } from "firebase/firestore";
import { ITiktokAccountMap } from "./tiktokAccountMap";
import { ITiktokOAuthMap } from "./tiktokOauthMap";

export enum TiktokAccountLinkingMapField {
  ACCOUNT = "account",
  AUTO_IMPORT_STATUS = "autoImportStatus",
  IS_AUTO_IMPORT_ENABLED = "isAutoImportEnabled",
  LAST_IMPORTED_AT = "lastImportedAt",
  OAUTH = "oAuth",
}

export enum TiktokAutoImportStatus {
  IDLE = "IDLE",
  PRE_PROGRESS = "PRE_PROGRESS",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface ITiktokAccountLinkingMap {
  [TiktokAccountLinkingMapField.ACCOUNT]?: ITiktokAccountMap;
  [TiktokAccountLinkingMapField.AUTO_IMPORT_STATUS]?: TiktokAutoImportStatus;
  [TiktokAccountLinkingMapField.IS_AUTO_IMPORT_ENABLED]?: boolean;
  [TiktokAccountLinkingMapField.LAST_IMPORTED_AT]?: Timestamp;
  [TiktokAccountLinkingMapField.OAUTH]?: ITiktokOAuthMap;
}
