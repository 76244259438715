const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const uppercaseFirstLetter = (str: string) =>
  str.charAt(0) + str.substring(1).toLowerCase();

export function getDateStringFromMilliseconds(
  milliseconds: number,
  format = "dd-mm-yyyy"
): string {
  const date = new Date(milliseconds);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return format
    .replace(/dd/g, ("0" + day).slice(-2))
    .replace(/mm/g, ("0" + (month + 1)).slice(-2))
    .replace(/MMM/g, months[month])
    .replace(/yyyy/g, year.toString());
}

export function getDateStringFromDate(
  date: Date,
  format = "dd-mm-yyyy"
): string {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return format
    .replace(/dd/g, ("0" + day).slice(-2))
    .replace(/mm/g, ("0" + (month + 1)).slice(-2))
    .replace(/MMM/g, months[month])
    .replace(/yyyy/g, year.toString());
}

export const validateHttpUrl = (website: string) => {
  return /^(?:(?:https|http):\/\/)?(?:www\.)?([^\s.]+\.\S{2}[:?\d]*)\S*$/.test(
    website
  );
};

export const validateUsername = (username: string) => {
  const reg = /^[a-z]([a-z0-9]([._]?))*$/;
  return reg.test(username);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
