import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { Collection } from "../../models/constants";
import {
  EpisodeField,
  EpisodeProcessStatus,
  EpisodeVisibility,
  IEpisode,
} from "../../models/entities";
import { firestore } from "./clientApp";
import { GetListResult, PaginationPayload } from "react-admin";
import { mapValuesDeep } from "./utils/mapValuesDeep";

export const episodeCollection = collection(firestore, Collection.EPISODES);

export const getEpisodeDiscoverTab = async (
  pagination: PaginationPayload
): Promise<GetListResult<any>> => {
  const { page, perPage } = pagination;
  const defaultQuery = query(
    episodeCollection,
    where(EpisodeField.PROCESS_STATUS, "==", EpisodeProcessStatus.COMPLETED),
    where(EpisodeField.SHOW_ON_DISCOVER, "==", true),
    where(EpisodeField.USER_SHOW_ON_DISCOVER, "==", true),
    where(EpisodeField.VISIBILITY, "==", EpisodeVisibility.PUBLIC),
    orderBy(EpisodeField.DISCOVER_PRIORITY, "desc"),
    orderBy(EpisodeField.PUBLISHED_AT, "desc")
  );

  const total = await getDocs(defaultQuery);

  let data: IEpisode[];
  if (page > 1) {
    const first = query(defaultQuery, limit((page - 1) * perPage));
    const documentSnapshots = await getDocs(first);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const next = query(defaultQuery, startAfter(lastVisible), limit(perPage));
    const result = await getDocs(next);
    data = result.docs.map((doc) => doc.data() as IEpisode);
  } else {
    const q = query(defaultQuery, limit(perPage));
    const result = await getDocs(q);
    data = result.docs.map((doc) => doc.data() as IEpisode);
  }

  return {
    data: mapValuesDeep(data),
    total: total.docs.length,
    pageInfo: {
      hasNextPage: total.docs.length > page * perPage,
      hasPreviousPage: page > 1,
    },
  };
};
