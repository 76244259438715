import Box from "@mui/system/Box";
import { PreviewItemContainer } from "./style";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";

interface ImportRssPreviewFeedDataProps {
  title: string;
  description: string;
  img: string;
}

const ImportRssPreviewFeedData = ({
  title,
  description,
  img,
}: ImportRssPreviewFeedDataProps) => (
  <PreviewItemContainer paddingY={4}>
    <Box marginX={4} marginBottom={2}>
      <Box
        width="100%"
        component="img"
        borderRadius="14px"
        src={img}
        alt="rss thumbnail"
      />
    </Box>
    <Stack marginX={2} gap={0.5} marginBottom={4}>
      <Typography color="#007b83" fontWeight={600} variant="subtitle1">
        TITLE
      </Typography>
      <Typography color="#3c4043" variant="body1">
        {title}
      </Typography>
    </Stack>
    <Stack marginX={2} gap={0.5}>
      <Typography color="#007b83" fontWeight={600} variant="subtitle1">
        DESCRIPTION
      </Typography>
      <Typography color="#3c4043" variant="body1">
        {description}
      </Typography>
    </Stack>
  </PreviewItemContainer>
);

export default ImportRssPreviewFeedData;
